import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Alert, Box, Button, Card, CardContent, CardHeader, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import LaunchIcon from '@mui/icons-material/Launch';

import { DrawerCustom } from './ui/DrawerCustom';

import { usePages } from '../hooks/usePages';

import { TablePaginationActions } from '../helpers/TablePaginationActions';

import { ObtenerJuzgados } from '../actions/admin/catalogos/juzgadoActions';
import { ObtenerEstados } from '../actions/admin/catalogos/estadoActions';
import { ObtenerMunicipios } from '../actions/admin/catalogos/municipioActions';

import { DetalleExpediente, FinalizarExpediente, GuardarExpediente } from '../actions/comun/expedienteActions';

export const DetallExpedientesScreen = ( ) => {

    const { Id } = useParams();

    const [modalMessage, setModalMessage] = useState( { openMessage: false, message: '', type: 'error' } );

    const {openMessage, message, type} = modalMessage;

    const [folio, setFolio] = useState( '' );
    const [numeroExpediente, setNumeroExpediente] = useState( '' );
    const [numeroExpedienteOrigen, setNumeroExpedienteOrigen] = useState( '' );        
    const [documento, setDocumento] = useState( '' );
    const [titular2, setTitular2] = useState( '' );

    const [distritoJudicial, setDistritoJudicial] = useState( 0 );
    
    const [juzgadoTemp, setJuzgadoTemp] = useState( 0 );
    const [juzgado, setJuzgado] = useState( 0 );
    const [juzgadoArray, setJuzgadoArray] = useState( [] );

    const [juzgadoOrigenTemp, setJuzgadoOrigenTemp] = useState( 0 );
    const [juzgadoOrigen, setJuzgadoOrigen] = useState( 0 );
    const [juzgadoOrigenArray, setJuzgadoOrigenArray] = useState( [] );

    const [rows, setRows] = useState( [ ] );
    
    const [loading, setLoading] = useState( true );
    const [loadingSave, setLoadingSave] = useState( false );
    const [loadingAjax, setLoadingAjax] = useState( true );
    const [loadingFinalizar, setLoadingFinalizar] = useState( false );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage ] = usePages( 10 );

    const [openAgregar, setOpenAgregar] = useState( false );
    const [openEliminar, setOpenEliminar] = useState( false );
    
    const [referencia, setReferencia] = useState( '' );
    
    const [nombre, setNombre] = useState( '' );
    const [apellido1, setApellido1] = useState( '' );
    const [apellido2, setApellido2] = useState( '' );
    const [creditos, setCreditos] = useState( '' );

    const [errores, setErrores] = useState( {} );

    const [applyChanges, setApplyChanges] = useState( false );
    const [countDemandados, setCountDemandados] = useState( 0 );

    const [ajaxFinish, setAjaxFinish] = useState( false );

    const [colonia, setColonia] = useState( '' );
    const [calle, setCalle] = useState( '' );
    const [codigoPostal, setCodigoPostal] = useState( '' );

    const [estado, setEstado] = useState( 0 );
    const [estadoArray, setEstadoArray] = useState( [] );

    const [municipio, setMunicipio] = useState( 0 );
    const [municipioEdit, setMunicipioEdit] = useState( 0 );
    const [municipioArray, setMunicipioArray] = useState( [] );

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }

        setModalMessage({
            ...modalMessage,
            openMessage: false,
        });     
    };

    const handleClosePersona = () => {

        setOpenAgregar( false );

        setReferencia( '' );
        setNombre( '' );
        setApellido1( '' );
        setApellido2( '' );
        setCreditos( '' );
        setTitular2( '' );

        setMunicipio( 0 );
        setMunicipioEdit( 0 );

        setEstado( 0 );
        setColonia( '' );
        setCalle( '' );
        setCodigoPostal( '' );

        setErrores( {} );
    }

    const handleEditar = ( data ) => {

        setReferencia( data?.id ?? '' );
        setNombre( data?.nombre ?? '' );
        setApellido1( data?.apellido1 ?? '' );
        setApellido2( data?.apellido2 ?? '' );
        setCreditos( data?.creditos ?? '' );

        setMunicipioEdit( data?.idMunicipio ?? 0 );
        setEstado( data?.idEstado ?? 0 );

        setCalle( data?.calle ?? '' );
        setColonia( data?.colonia ?? '' );
        setCodigoPostal( data?.codigoPostal ?? '' );
        setTitular2( data?.titular2 ?? '' );

        setOpenAgregar( true );
    }

    const validateFields = () => {
        
        let valid = true;
        let errores = {};

        if ( nombre === '' ) {
            valid = false
            errores.nombre = 'Escribe el nombre'
        }

        if ( apellido1 === '' ) {
            valid = false
            errores.apellido1 = 'Escribe el apellido 1'
        }   
        
        if( !creditos ){
            errores.creditos = 'Escribe los creditos';
            valid=  false;
        }

        if( !calle ){
            errores.calle = 'Escribe la calle';
            valid=  false;
        }

        if( !colonia ){
            errores.colonia = 'Escribe la colonia';
            valid=  false;
        }

        if( !codigoPostal ){
            errores.codigoPostal = 'Escribe el código postal';
            valid=  false;
        }

        if( estado === 0 ){
            errores.estado = 'Selecciona un estado';
            valid=  false;
        }

        if( estado !== 0 && municipio === 0 ){
            errores.municipio = 'Selecciona un municipio';
            valid=  false;
        }

        setErrores( errores );

        return valid;
    }

    const handleGuardarPersona = () => {

        const valid = validateFields();

        if( !valid ){
            return false;
        }       

        const descMunicipio = municipioArray?.find((e) => e.idMunicipio === municipio)?.descripcion ?? '';
        const descEstado = estadoArray?.find((e) => e.idEstado === estado)?.descripcion ?? '';

        if( referencia ){
            setRows( rows.map( ( elem ) => {
                    if( elem.id === referencia ){
                        elem.nombre = nombre;
                        elem.apellido1 = apellido1;
                        elem.apellido2 = apellido2;
                        elem.creditos = creditos;
                        elem.calle = calle;
                        elem.colonia = colonia;
                        elem.codigoPostal = codigoPostal;
                        elem.idMunicipio = municipio;
                        elem.idEstado = estado;
                        elem.municipio = descMunicipio;
                        elem.estado = descEstado;
                        elem.titular2 = titular2;
                        elem.ubicacion = calle + ', ' + colonia + ', C.P. ' + codigoPostal + ', ' + descMunicipio + ', ' + descEstado;
                    }
                    return elem;
                })
            );
        }
        else {
            const id = new Date().getUTCSeconds() + '' + new Date().getUTCMilliseconds();
            setRows([ 
                ...rows,
                {
                    id: 'N-' + id + '' + rows.length,
                    nombre: nombre,
                    apellido1: apellido1,
                    apellido2: apellido2,
                    creditos: creditos,
                    calle: calle,
                    colonia: colonia,
                    codigoPostal: codigoPostal,
                    idMunicipio: municipio,
                    idEstado: estado,
                    municipio: descMunicipio,
                    estado: descEstado,
                    titular2: titular2,
                    ubicacion: calle + ', ' + colonia + ', C.P. ' + codigoPostal + ', ' + descMunicipio + ', ' + descEstado,
                }
            ]);
        }       

        handleClosePersona();
        
    }

    const handleEliminarPersona = () => {
       
        if( referencia ){
            setRows( 
                rows.filter( ( elem ) => elem.id !== referencia )
            );
        }

        setOpenEliminar( false );
        setReferencia( '' );
    }

    const handleGuardarExpediente = async () => {

        setErrores( {} );

        let errores = {};
        let valid = true;

        const dataArray = rows.filter( ( elem ) => (elem.nombre === '' && elem.apellido1 === '') || (elem.nombre !== '' && elem.apellido1 === '')  || (elem.nombre === '' && elem.apellido1 !== '') );

        if( dataArray.length !== 0 ){

            errores.dataArray = 'Agrega y/o edita los demandados';
            valid =  false;
        }

        if( rows.length === 0 ){

            errores.dataArray = 'Debes agregar los demandados';
            valid =  false;
        }

        if( !valid ){
            setErrores( errores );
            return false;
        }

        setLoadingSave( true );

        const params = {
            id: Id,
            numeroExpedienteOrigen,
            idJuzgadoOrigen: juzgadoOrigen,
            creditos,
            demandados: rows,
        };

        await GuardarExpediente( params ).then( response => {

            if( response ){

                if( response.data ){

                    const { success, message, demandadosRegistrados } = response.data;

                    setTimeout(() => {

                        if( success ){
                            setModalMessage( { openMessage: true , message: message, type: 'success' } );

                            let array = [];

                            demandadosRegistrados.forEach(( element) => {

                                array.push({
                                    id: element.id,
                                    nombreCompleto: element.nombre + ' ' + element.apellido1 + ' ' + element.apellido2,
                                    nombre: element.nombre,
                                    apellido1: element.apellido1,
                                    apellido2: element.apellido2,
                                    creditos: element.creditos,
                                    calle: element.calle,
                                    codigoPostal: element.codigoPostal,
                                    colonia: element.colonia,
                                    idMunicipio: element.idMunicipio,
                                    idEstado: element.idEstado,
                                    municipio: element.municipio,
                                    estado: element.estado,
                                    titular2: element.titular2,
                                    ubicacion: element.calle + ', ' + element.colonia + ', C.P. ' + element.codigoPostal + ', ' + element.municipio + ', ' + element.estado,
                                });

                            });

                            setRows( array );
                            setCountDemandados( array?.length ?? 0 );
                            setApplyChanges( true );
                        }
                        else {
                            setModalMessage( { openMessage: true , message: message, type: 'error' } );
                        }

                        setLoadingSave( false );
                        
                    }, 1000);                   

                }
                else {
                    setModalMessage( { openMessage: true , message: 'Ocurrio un error, contacte con el administrador del sistema', type: 'error' } );

                    setLoadingSave( false );
                }

            }
            else {
                setModalMessage( { openMessage: true , message: 'Ocurrio un error, contacte con el administrador del sistema', type: 'error' } );

                setLoadingSave( false );
            }           

        });
    }

    const handleFinalizarExpediente = async () => {

        setLoadingFinalizar( true );

        await FinalizarExpediente({ id: Id, }).then( response => {

            if( response ){

                if( response.data ){

                    const { success, message } = response.data;

                    setTimeout(() => {

                        if( success ){
                            
                            setAjaxFinish( true );
                          
                        }
                        else {
                            setModalMessage( { openMessage: true , message: message, type: 'error' } );
                        }

                        setLoadingSave( false );
                        
                    }, 1000);                   

                }
                else {
                    setModalMessage( { openMessage: true , message: 'Ocurrio un error, contacte con el administrador del sistema', type: 'error' } );

                    setLoadingFinalizar( false );
                }

            }
            else {
                setModalMessage( { openMessage: true , message: 'Ocurrio un error, contacte con el administrador del sistema', type: 'error' } );

                setLoadingFinalizar( false );
            }           

        });
    }

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerJuzgados().then( response => {

                if( response ){

                    if( response.data ){

                        setJuzgadoArray( response.data );

                    }

                }

            });

        }

        Obtener();
        
    }, [ ])

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerJuzgados().then( response => {

                if( response ){

                    if( response.data ){

                        setJuzgadoOrigenArray( response.data );

                    }

                }

            });

        }

        Obtener();
        
    }, [ ])

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerEstados().then( response => {

                if( response ){

                    if( response.data ){

                        setEstadoArray( response.data );

                    }

                }

            });

        }

        Obtener();
        
    }, [ ])

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerMunicipios( estado ).then( response => {

                if( response ){

                    if( response.data ){

                        setMunicipioArray( response.data );

                    }

                }

            });

        }

        Obtener();
        
    }, [ estado ])

    useEffect(() => {
      
        if( estado !== 0 && municipioEdit !== 0 && municipioArray.length !== 0){

            setMunicipio( municipioEdit );

        }
        
    }, [ estado, municipioEdit, municipioArray ])

    useEffect(() => {
      
        async function Obtener(){

            setLoading( true );

            await DetalleExpediente({ Id }).then( response => {

                if( response ){

                    if( response.data ){

                       const { success } = response.data;

                        if( success ){

                            setFolio( response?.data?.folio ?? '' );
                            setNumeroExpediente( response?.data?.numeroExpediente ?? '' );
                            setNumeroExpedienteOrigen( response?.data?.numeroExpedienteOrigen ?? '' );
                            setCreditos( response?.data?.creditos ?? '' );
                            setJuzgadoTemp( response?.data?.idJuzgado ?? 0 );
                            setJuzgadoOrigenTemp( response?.data?.idJuzgadoOrigen ?? 0 );
                            setDocumento( response?.data?.documento ?? '' );
                            setApplyChanges( response?.data?.registroCorrecto ?? false );

                            setDistritoJudicial( response?.data?.idDistritoJudicial ?? 0 );

                            const demandadosRegistrados = response?.data?.demandadosRegistrados;

                            if( demandadosRegistrados?.length !== 0 ){

                                let array = [];

                                demandadosRegistrados.forEach(( element) => {

                                    array.push({
                                        id: element.id,
                                        nombreCompleto: element.nombre + ' ' + element.apellido1 + ' ' + element.apellido2,
                                        nombre: element.nombre,
                                        apellido1: element.apellido1,
                                        apellido2: element.apellido2,
                                        creditos: element.creditos,
                                        calle: element.calle,
                                        codigoPostal: element.codigoPostal,
                                        colonia: element.colonia,
                                        idMunicipio: element.idMunicipio,
                                        idEstado: element.idEstado,
                                        municipio: element.municipio,
                                        estado: element.estado,
                                        titular2: element.titular2,
                                        ubicacion: element.calle + ', ' + element.colonia + ', C.P. ' + element.codigoPostal + ', ' + element.municipio + ', ' + element.estado,
                                    });

                                });

                                setRows( array );
                                setCountDemandados( array?.length ?? 0 );
                            }                           

                            if( response?.data?.idEstatus === 4 ){

                                setTimeout(() => {

                                    setAjaxFinish( true );  
                                    setLoadingAjax( false );                            

                                }, 1000);
                            }
                            else {                               

                                setTimeout(() => {

                                    setLoadingAjax( false );
                                    setLoading( false );                                

                                }, 1000);
                            }
                            
                        }

                    }

                }

            });

        }

        if( Id ){
            Obtener();
        }
        
    }, [ Id ])   

    useEffect(() => {      
        
        if( juzgadoTemp !== 0 && juzgadoArray.length > 0 ){
            setJuzgado( juzgadoTemp );
        }
      
    }, [ juzgadoTemp, juzgadoArray ])

    useEffect(() => {      
        
        if( juzgadoOrigenTemp !== 0 && juzgadoOrigenArray.length > 0 ){
            setJuzgadoOrigen( juzgadoOrigenTemp );
        }
      
    }, [ juzgadoOrigenTemp, juzgadoOrigenArray ])

    return (
        <>
            <Snackbar open={ openMessage } anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={ handleClose }>
                <Alert onClose={ handleClose } variant="filled" severity={ type } sx={{ width: '100%' }}>
                    { message }
                </Alert>
            </Snackbar>

            <DrawerCustom>
                <>
                    {
                        ajaxFinish
                        ?
                            <Box sx={{ marginTop: '10em', minHeight: '100%', py: 3 }}>  

                                <Container maxWidth="lg" >                        

                                    <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                        
                                        <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                        
                                            <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                                Se finalizó el registro
                                            </Typography>

                                            <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                                No se tienen actividades pendientes para este expediente
                                            </Typography>

                                        </CardContent>

                                    </Card>                   
                                                            
                                </Container>                    

                            </Box>
                        :
                            loadingAjax
                            ?
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} sx={{ mt: '15%', textAlign: 'center' }}>
                                        <CircularProgress size={50} />
                                    </Grid>
                                </Grid>
                            :                    
                            <>
                                <Grid container spacing={3}>

                                    <Grid item xs={12} md={12} sx={{ mb: 3 }}>
                                        <Typography variant='h4'>Detalle del expediente</Typography>
                                    </Grid>

                                    <Grid item xs={12} md={6}> 

                                        <Grid container spacing={3}>

                                            <Grid item xs={12} md={6}> 
                                                
                                                <TextField 
                                                    label="Folio" 
                                                    autoComplete="off"
                                                    fullWidth
                                                    disabled
                                                    value={ folio }
                                                    onChange={ (e) => { setFolio( e.target.value ); } }
                                                /> 

                                            </Grid>

                                            <Grid item xs={12} md={6}> 
                                            
                                                <TextField 
                                                    label="Número de expediente" 
                                                    autoComplete="off"
                                                    fullWidth
                                                    disabled
                                                    value={ numeroExpediente }
                                                    onChange={ (e) => { setNumeroExpediente( e.target.value ); } }
                                                /> 

                                            </Grid>

                                            <Grid item xs={12} md={12}> 

                                                <FormControl fullWidth>
                                                    <InputLabel id="select-juzgado-label">Juzgado</InputLabel>
                                                
                                                    <Select
                                                        labelId="select-juzgado-label"
                                                        id="select-juzgado"                                            
                                                        label="Juzgado"
                                                        name="juzgado"
                                                        disabled
                                                        value={ juzgado }
                                                        onChange={ (e) => { setJuzgado( e.target.value ); } }
                                                    >
                                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                    
                                                        {
                                                            juzgadoArray
                                                            .filter( ( elem ) => { return elem.idJuzgado === 72 || elem.idJuzgado === 74; })
                                                            .map( ( elem ) => 
                                                                <MenuItem key={ elem.idJuzgado } value={ elem.idJuzgado }>{ elem.descripcion }</MenuItem> 
                                                            )
                                                        }                     
                                                        
                                                    </Select>

                                                </FormControl>
                                                
                                            </Grid>

                                            <Grid item xs={12} md={6}> 
                                            
                                                <TextField 
                                                    label="Número de expediente de origen" 
                                                    autoComplete="off"
                                                    fullWidth
                                                    disabled
                                                    value={ numeroExpedienteOrigen }
                                                    onChange={ (e) => { setNumeroExpedienteOrigen( e.target.value ); } }
                                                /> 

                                            </Grid>

                                            <Grid item xs={12} md={12}> 

                                                <FormControl fullWidth>
                                                    <InputLabel id="select-juzgadoOrigen-label">Juzgado de origen</InputLabel>
                                                
                                                    <Select
                                                        labelId="select-juzgadoOrigen-label"
                                                        id="select-juzgadoOrigen"                                            
                                                        label="Juzgado de origen"
                                                        name="juzgadoOrigen"          
                                                        disabled                                  
                                                        value={ juzgadoOrigen }
                                                        onChange={ (e) => { setJuzgadoOrigen( e.target.value ); } }
                                                    >
                                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                    
                                                        {
                                                            juzgadoOrigenArray          
                                                            .filter( ( elem ) => { return elem.idMateria === 1 && elem.idDistritoJudicial === distritoJudicial; })                              
                                                            .map( ( elem ) => 
                                                                <MenuItem key={ elem.idJuzgado } value={ elem.idJuzgado }>{ elem.descripcion }</MenuItem> 
                                                            )
                                                        }                     
                                                        
                                                    </Select>

                                                </FormControl>
                                                
                                            </Grid>

                                        </Grid>

                                    </Grid>

                                    <Grid item xs={12} md={6} sx={{ height: 450 }}> 

                                        <iframe 
                                            title='iframe documento'
                                            src={ documento }
                                            allowFullScreen 
                                            width="100%"
                                            height="100%"
                                            style={{ position: "relative", height: "100%" }}
                                        >
                                        </iframe>
                                         
                                        <Button
                                            variant='text'
                                            href={ documento }
                                            sx={{ textTransform: 'none', padding: 0, mt: 1, whiteSpace: 'pre-wrap' }}
                                            target='_blank'
                                            endIcon={ <LaunchIcon /> }
                                        >
                                            Abrir documento en otra pestaña
                                        </Button>

                                    </Grid>

                                    <Grid item xs={12} md={12} sx={{ mt: 5, textAlign: 'right' }}> 

                                        <Button
                                            color='primary'
                                            variant='contained'
                                            size='small'
                                            startIcon={ <AddIcon /> }
                                            onClick={ () => setOpenAgregar( true ) }
                                        >
                                            Agregar persona
                                        </Button>

                                    </Grid>

                                    <Grid item md={12} xs={12}>

                                        <TableContainer>

                                            <Table>
                                                
                                                <TableHead>

                                                    <TableRow>  
                                                        
                                                        <TableCell style={{ width:'5%' }}> NOMBRE </TableCell>
                                                        <TableCell style={{ width:'5%' }}> APELLIDO 1 </TableCell>
                                                        <TableCell style={{ width:'5%', textAlign: 'center' }}> APELLIDO 2 </TableCell>
                                                        <TableCell style={{ width:'5%', textAlign: 'center' }}> CREDITOS </TableCell>

                                                        <TableCell style={{ width:'15%', textAlign: 'center' }}> TITULAR 2 </TableCell>

                                                        <TableCell style={{ width:'20%', textAlign: 'center' }}> UBICACIÓN DEL INMUEBLE</TableCell>
                                                        <TableCell style={{ width:'0.5%' }}>  </TableCell>
                                                        <TableCell style={{ width:'0.5%' }}>  </TableCell>

                                                    </TableRow>

                                                </TableHead>

                                                <TableBody>

                                                    {
                                                        (
                                                            rowsPerPage > 0
                                                            ?
                                                            rows.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                                                            :
                                                            rows
                                                        ).map( ( row ) => (

                                                            <TableRow key={ row.id } >
                                                                
                                                                <TableCell> { row?.nombre ?? '' } </TableCell>
                                                                <TableCell> { row?.apellido1 ?? '' } </TableCell>
                                                                <TableCell> { row?.apellido2 ?? '' } </TableCell>
                                                                <TableCell style={{ fontWeight:'bold', textAlign: 'center' }}> { row?.creditos ?? '' } </TableCell>

                                                                <TableCell> { row?.titular2 ?? '' } </TableCell>

                                                                <TableCell> { row?.ubicacion ?? '' } </TableCell>

                                                                <TableCell> 

                                                                    <Button
                                                                        color='primary'
                                                                        variant='contained'
                                                                        size='small'
                                                                        onClick={ () => handleEditar( row ) }
                                                                    >
                                                                        <EditIcon fontSize='small' />
                                                                    </Button>

                                                                </TableCell>

                                                                <TableCell> 

                                                                    <Button
                                                                        color='primary'
                                                                        variant='contained'
                                                                        size='small'
                                                                        onClick={ () => { setReferencia( row.id ); setOpenEliminar( true ); } }
                                                                    >
                                                                        <DeleteIcon fontSize='small' />
                                                                    </Button>

                                                                </TableCell>

                                                            </TableRow>

                                                        ))
                                                    }

                                                    { 
                                                        rows.length === 0 && (
                                                            <TableRow style={{ height: 53 }}>

                                                                <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                                    {
                                                                        loading
                                                                        ?
                                                                            <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                                        :
                                                                            ''
                                                                    }
                                                                    {
                                                                        !loading && rows.length === 0
                                                                        ?
                                                                            'No se encontraron personas'
                                                                        :
                                                                            ''
                                                                    }
                                                                </TableCell>

                                                            </TableRow>
                                                        )
                                                    }
                                                </TableBody>

                                                <TableFooter>

                                                    <TableRow>
                                            
                                                        <TablePagination
                                                            rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                            colSpan={10}
                                                            count={rows.length}                                        
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}                                                                                
                                                            onPageChange={ handleChangePage }
                                                            onRowsPerPageChange={ handleChangeRowsPerPage }
                                                            ActionsComponent={ TablePaginationActions }

                                                            labelRowsPerPage="Filas por página"                                            
                                                            labelDisplayedRows={
                                                                ({ from, to, count }) => {
                                                                return '' + from + '-' + to + ' de ' + count
                                                                }
                                                            }  
                                                        />

                                                    </TableRow>

                                                </TableFooter>

                                            </Table>

                                        </TableContainer>

                                        {
                                            errores.dataArray && ( <Alert severity='warning' variant='filled' sx={{ mt: 1 }}> { errores.dataArray } </Alert>)                          
                                        }

                                    </Grid>

                                    <Grid item xs={12} md={12} sx={{ mt: 3 }}> 

                                        <Grid container spacing={3}>   

                                            <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>

                                                <LoadingButton
                                                    color='primary'
                                                    variant='contained'
                                                    size='small'
                                                    loading={ loadingSave }
                                                    startIcon={ <AddIcon /> }
                                                    onClick={ handleGuardarExpediente }
                                                >
                                                    guardar cambios
                                                </LoadingButton>
                                            
                                            </Grid>

                                            <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>

                                                <LoadingButton
                                                    color='primary'
                                                    variant='contained'
                                                    size='small'
                                                    startIcon={ <CheckIcon /> }
                                                    loading={ loadingFinalizar }
                                                    disabled={ 
                                                        ( rows.length !== countDemandados )  ||
                                                        ( 
                                                            rows.filter( ( elem ) => 
                                                                (elem.nombre === '' && elem.apellido1 === '') || 
                                                                (elem.nombre !== '' && elem.apellido1 === '')  || 
                                                                (elem.nombre === '' && elem.apellido1 !== '') ).length !== 0
                                                        ) ||
                                                        !applyChanges
                                                    }
                                                    onClick={ handleFinalizarExpediente }
                                                >
                                                    Finalizar registro
                                                </LoadingButton>
                                            
                                            </Grid>
                                        
                                        </Grid>

                                    </Grid>

                                </Grid>

                                <Dialog
                                    open={ openAgregar }
                                    onClose={ () => { } }
                                    maxWidth='md'
                                    fullWidth
                                >
                                    <DialogTitle>
                                        { referencia ? 'Actualizar datos de la persona': 'Agregar una nueva persona'}
                                    </DialogTitle>
                                
                                    <DialogContent>

                                        <Grid container spacing={3} sx={{}}> 

                                            <Grid item xs={12}>

                                                <Card>

                                                    <CardHeader
                                                        sx={{ backgroundColor: (theme) => theme.palette.primary.main }} 
                                                        subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Información de la persona </Typography> }
                                                    />
                                                    
                                                    <Divider />
                                                
                                                    <CardContent> 

                                                        <Grid container spacing={3} >                                      

                                                            <Grid item md={4} xs={12}>

                                                                <TextField 
                                                                    name='nombres'
                                                                    label='Nombre(s)'
                                                                    autoComplete='off'  
                                                                    fullWidth
                                                                    value={ nombre }
                                                                    onChange={ (e) => setNombre( e.target.value.toUpperCase() ) }
                                                                />

                                                                {
                                                                    errores.nombre && ( <Alert severity='warning' variant='filled' sx={{ mt: 1 }}> { errores.nombre } </Alert>)                          
                                                                }

                                                            </Grid>                                            

                                                            <Grid item md={4} xs={12}>

                                                                <TextField 
                                                                    name='apellido1'
                                                                    label='Apellido 1'
                                                                    fullWidth
                                                                    value={ apellido1 }
                                                                    onChange={ (e) => setApellido1( e.target.value.toUpperCase() ) }
                                                                />

                                                                {
                                                                    errores.apellido1 && ( <Alert severity='warning' variant='filled' sx={{ mt: 1 }}> { errores.apellido1 } </Alert>)                          
                                                                }

                                                            </Grid>

                                                            <Grid item md={4} xs={12}>

                                                                <TextField 
                                                                    name='apellido2'
                                                                    label='Apellido 2'
                                                                    fullWidth
                                                                    value={ apellido2 }
                                                                    onChange={ (e) => setApellido2( e.target.value.toUpperCase() ) }
                                                                />

                                                            </Grid> 

                                                            <Grid item md={4} xs={12}> 

                                                                <TextField 
                                                                    label="Creditos" 
                                                                    autoComplete="off"
                                                                    fullWidth
                                                                    value={ creditos }
                                                                    onChange={ (e) => { setCreditos( e.target.value ); } }
                                                                /> 

                                                                {
                                                                    errores.creditos && ( <Alert severity='warning' variant='filled' sx={{ mt: 1 }}> { errores.creditos } </Alert>)                          
                                                                }

                                                            </Grid>

                                                            <Grid item md={8} xs={12}> 

                                                                <TextField 
                                                                    label="Titular 2" 
                                                                    autoComplete="off"
                                                                    fullWidth
                                                                    value={ titular2 }
                                                                    onChange={ (e) => { setTitular2( e.target.value ); } }
                                                                /> 

                                                            </Grid>

                                                        </Grid>

                                                    </CardContent>
                                                    
                                                </Card>
                                                
                                            </Grid>

                                            <Grid item xs={12}>

                                                <Card>

                                                    <CardHeader
                                                        sx={{ backgroundColor: (theme) => theme.palette.primary.main }} 
                                                        subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Ubicación del inmueble  </Typography> }
                                                    />
                                                    
                                                    <Divider />
                                                
                                                    <CardContent> 

                                                        <Grid container spacing={3} >     

                                                            <Grid item md={12} xs={12}>

                                                                <TextField 
                                                                    label='Calle o vialidad'
                                                                    fullWidth
                                                                    multiline
                                                                    rows={4}
                                                                    value={ calle }
                                                                    onChange={ (e) => setCalle( e.target.value.toUpperCase() ) }
                                                                />

                                                                {
                                                                    errores.calle && ( <Alert severity='warning' variant='filled' sx={{ mt: 1 }}> { errores.calle } </Alert>)                          
                                                                }

                                                            </Grid>       

                                                            <Grid item md={6} xs={12}> 

                                                                <TextField 
                                                                    label="Colonia" 
                                                                    autoComplete="off"
                                                                    fullWidth
                                                                    value={ colonia }
                                                                    onChange={ (e) => { setColonia( e.target.value.toUpperCase() ); } }
                                                                /> 

                                                                {
                                                                    errores.colonia && ( <Alert severity='warning' variant='filled' sx={{ mt: 1 }}> { errores.colonia } </Alert>)                          
                                                                }

                                                            </Grid> 

                                                            <Grid item md={6} xs={12}>

                                                                <TextField 
                                                                    label='Código postal'
                                                                    fullWidth
                                                                    value={ codigoPostal }
                                                                    onChange={ (e) => setCodigoPostal( e.target.value ) }
                                                                />

                                                                {
                                                                    errores.codigoPostal && ( <Alert severity='warning' variant='filled' sx={{ mt: 1 }}> { errores.codigoPostal } </Alert>)                          
                                                                }

                                                            </Grid>                            

                                                            <Grid item md={6} xs={12}>

                                                                <FormControl fullWidth>
                                                                    <InputLabel id="select-estado-label">Estado</InputLabel>
                                                                
                                                                    <Select
                                                                        labelId="select-estado-label"
                                                                        id="select-estado"                                            
                                                                        label="Estado"
                                                                        name="estado"
                                                                        value={ estado }
                                                                        onChange={ (e) => { setMunicipio( 0 ); setEstado( e.target.value ); } }
                                                                    >
                                                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                                    
                                                                        {
                                                                            estadoArray                                                                           
                                                                            .map( ( elem ) => 
                                                                                <MenuItem key={ elem.idEstado } value={ elem.idEstado }>{ elem.descripcion }</MenuItem> 
                                                                            )
                                                                        }                     
                                                                        
                                                                    </Select>

                                                                </FormControl>

                                                                {
                                                                    errores.estado && ( <Alert severity='warning' variant='filled' sx={{ mt: 1 }}> { errores.estado } </Alert>)                          
                                                                }
                                                              
                                                            </Grid>                                            

                                                            <Grid item md={6} xs={12}>
                                                             
                                                                <FormControl fullWidth>
                                                                    <InputLabel id="select-municipio-label">Municipio</InputLabel>
                                                                
                                                                    <Select
                                                                        labelId="select-municipio-label"
                                                                        id="select-municipio"                                            
                                                                        label="Municipio"
                                                                        name="municipio"
                                                                        disabled={ estado === 0 }
                                                                        value={ municipio }
                                                                        onChange={ (e) => { setMunicipio( e.target.value ); } }
                                                                    >
                                                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                                    
                                                                        {
                                                                            municipioArray
                                                                            .map( ( elem ) => 
                                                                                <MenuItem key={ elem.idMunicipio } value={ elem.idMunicipio }>{ elem.descripcion }</MenuItem> 
                                                                            )
                                                                        }                     
                                                                        
                                                                    </Select>

                                                                </FormControl>

                                                                {
                                                                    errores.municipio && ( <Alert severity='warning' variant='filled' sx={{ mt: 1 }}> { errores.municipio } </Alert>)                          
                                                                }
                                                                
                                                            </Grid>                                                           

                                                        </Grid>

                                                    </CardContent>
                                                    
                                                </Card>
                                                
                                            </Grid>

                                        </Grid>

                                    </DialogContent>

                                    <DialogActions>
                                        <Button onClick={ handleClosePersona }>Cancelar</Button>
                                        <Button 
                                            variant='contained' 
                                            color='primary' onClick={ handleGuardarPersona } 
                                            startIcon={ <SaveIcon /> } 
                                        >                                        
                                            Guardar
                                        </Button>
                                    </DialogActions>

                                </Dialog>

                                <Dialog
                                    open={ openEliminar }
                                    onClose={ () => { } }
                                    maxWidth='xs'
                                    fullWidth
                                >
                                    <DialogTitle>
                                        Eliminar persona
                                    </DialogTitle>
                                
                                    <DialogContent>  
                                        <DialogContentText>¿Desea eliminar a la persona seleccionada?</DialogContentText>
                                    </DialogContent>

                                    <DialogActions>
                                        <Button onClick={ () => setOpenEliminar( false ) }>Cancelar</Button>
                                        <Button 
                                            variant='contained' 
                                            color='primary' onClick={ handleEliminarPersona } 
                                            startIcon={ <CheckIcon /> } 
                                        >                                        
                                            Aceptar
                                        </Button>
                                    </DialogActions>

                                </Dialog>
                                
                            </>                       

                    }
                
                </>
            </DrawerCustom>
        
        </>
    );

}
