import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

//import { Navbar } from '../components/ui/Navbar';

import { NotFoundScreen } from '../components/auth/NotFoundScreen';
//import { RegistroHipotecarioScreen } from '../components/RegistroHipotecarioScreen';

import { DistritoJudicialScreen } from '../components/Admin/catalogos/distritoJudicialScreen';
import { EstatusScreen } from '../components/Admin/catalogos/estatusScreen';
import { TipoSesionScreen } from '../components/Admin/catalogos/tipoSesionScreen';
import { TipoUsuarioScreen } from '../components/Admin/catalogos/tipoUsuarioScreen';
import { JuzgadoScreen } from '../components/Admin/catalogos/juzgadoScreen';
import { MateriaScreen } from '../components/Admin/catalogos/materiaScreen';
import { TipoMovimientoScreen } from '../components/Admin/catalogos/tipoMovimientoScreen';

import { UsuariosScreen } from '../components/Admin/usuarios/UsuarioScreen';

import { ExpedientesAsignadosScreen } from '../components/ExpedientesAsignadosScreen';
import { ExpedientesCapturadosScreen } from '../components/ExpedientesCapturadosScreen';
import { DetallExpedientesScreen } from '../components/DetalleExpedienteScreen';
import { DetallExpedientesCompletoScreen } from '../components/DetalleExpedienteCompletoScreen';

export const RegistroHipotecarioRoutes = () => {

    return (
        <Routes>

            <Route path="/inicio/" element={ <ExpedientesAsignadosScreen /> } />   

            {/* <Route path="/expedientesAsignados/" element={ <ExpedientesAsignadosScreen /> } />    */}

            <Route path="/expedientesCapturados/" element={ <ExpedientesCapturadosScreen /> } />   

            <Route path="/detalle/:Id" element={ <DetallExpedientesScreen /> } />   
            
            <Route path="/detalleExpediente/:Id" element={ <DetallExpedientesCompletoScreen /> } />   

            <Route path="/catalogos/distritoJudicial/" element={ <DistritoJudicialScreen /> } />  

            <Route path="/catalogos/estatus/" element={ <EstatusScreen /> } />     

            <Route path="/catalogos/tipoSesion/" element={ <TipoSesionScreen /> } />     

            <Route path="/catalogos/tipoUsuario/" element={ <TipoUsuarioScreen /> } />    

            <Route path="/catalogos/juzgado/" element={ <JuzgadoScreen /> } />     
            
            <Route path="/catalogos/materia/" element={ <MateriaScreen /> } />      

            <Route path="/catalogos/tipoMovimiento/" element={ <TipoMovimientoScreen /> } />     
            
            <Route path="/usuarios/" element={ <UsuariosScreen /> } />       
                 
            {/* Redirecciones */}

            <Route path="/" element={ <Navigate to="/inicio/" /> } />

            <Route path="*" element={ <NotFoundScreen /> } />

        </Routes>
    )

}