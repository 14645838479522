import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import ListIcon from '@mui/icons-material/List';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { usePages } from '../hooks/usePages';

import { TablePaginationActions } from '../helpers/TablePaginationActions';

import { ObtenerExpedientesAsignados, ObtenerExpedientesAsignadosFiltro } from '../actions/comun/expedienteActions';

import { DrawerCustom } from './ui/DrawerCustom';

import { ObtenerJuzgados } from '../actions/admin/catalogos/juzgadoActions';

export const ExpedientesAsignadosScreen = () => {

    const navigate = useNavigate();

    const [rows, setRows] = useState( [ ] );

    const [loading, setLoading] = useState( true );

    const [recordsCount, setRecordsCount] = useState( 0 );

    const [numeroExpedienteSearch, setNumeroExpedienteSearch] = useState( '' );
    const [loadingSearch, setLoadingSearch] = useState( false );
    const [filtroAplicado, setFiltroAplicado] = useState( false );
    const [reload, setReload] = useState( false );

    const [modalMessage, setModalMessage] = useState( { openMessage: false, message: '', type: 'error' } );

    const {openMessage, message, type} = modalMessage;

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage ] = usePages( 10 );

    const [juzgado, setJuzgado] = useState( 0 );
    const [juzgadoArray, setJuzgadoArray] = useState( [] );

    const [loadingDocumento, setLoadingDocumento] = useState( true );
    const [documento, setDocumento] = useState( '' );
    const [openDocumento, setOpenDocumento] = useState( false );

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }

        setModalMessage({
            ...modalMessage,
            openMessage: false,
        });     
    };

    const handleBuscarExpedienteFiltro = () => {

        setLoadingSearch( true );
        setFiltroAplicado( true ); 
        resetPage();

    }
  
    const handleClearFiltro = () => {

        setFiltroAplicado( false ); 
        setNumeroExpedienteSearch( '' );
        setJuzgado( 0 );

    }

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerJuzgados().then( response => {

                if( response ){

                    if( response.data ){

                        setJuzgadoArray( response.data );

                    }

                }

            });

        }

        Obtener();
        
    }, [ ])

    useEffect(() => {
      
        async function Obtener(){

            setRows( [ ] );
            setLoading( true );

            await ObtenerExpedientesAsignados({ page, rowsPerPage }).then( response => {

                if( response ){

                    if( response.data ){

                        setTimeout(() => {
                            
                            setRecordsCount( response.data.recordsCount ?? 0 );
                            setRows( response.data.records ?? [ ] );
                            setLoading( false );

                        }, 1000);                            

                    }
                    else{
                        setLoading( false );
                    }
                }
                else{
                    setLoading( false );
                }
            });

        }

        if( !filtroAplicado ){
            Obtener();
        }
        
    }, [ page, rowsPerPage, filtroAplicado ])     

    useEffect(() => {
      
        async function Obtener(){

            setRows( [ ] );
            setLoading( true );

            await ObtenerExpedientesAsignadosFiltro({ page, rowsPerPage, numeroExpediente: numeroExpedienteSearch, idJuzgado: juzgado }).then( response => {

                if( response ){

                    if( response.data ){

                        setTimeout(() => {
                            
                            setRecordsCount( response.data.recordsCount ?? 0 );
                            setRows( response.data.records ?? [ ] );
                            setLoading( false );
                            setLoadingSearch( false );

                        }, 1000);                            

                    }
                    else{
                        setLoading( false );
                        setLoadingSearch( false );
                    }
                }
                else{
                    setLoading( false );
                    setLoadingSearch( false );
                }
            });

        }

        if( filtroAplicado ){
            Obtener();
        }
        
    }, [ page, rowsPerPage, filtroAplicado, numeroExpedienteSearch, juzgado ])     

    useEffect(() => {
      
        async function Obtener(){

            setRows( [ ] );
            setLoading( true );

            await ObtenerExpedientesAsignados({ page, rowsPerPage }).then( response => {

                if( response ){

                    if( response.data ){

                        setTimeout(() => {
                            
                            setRecordsCount( response.data.recordsCount ?? 0 );
                            setRows( response.data.records ?? [ ] );
                            setLoading( false );
                            setReload( false );

                        }, 1000);                            

                    }
                    else{
                        setLoading( false );
                    }
                }
                else{
                    setLoading( false );
                }
            });

        }

        if( reload ){
            Obtener();
        }
        
    }, [ page, rowsPerPage, reload ])   

    return (
        <>

            <Snackbar open={ openMessage } anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={ handleClose }>
                <Alert onClose={ handleClose } variant="filled" severity={ type } sx={{ width: '100%' }}>
                    { message }
                </Alert>
            </Snackbar>
           
            <DrawerCustom>
                <>

                    <Grid container spacing={3}>

                        <Grid item xs={12} md={12} sx={{ mb: 3 }}>
                            <Typography variant='h4'>Expedientes asignados</Typography>
                        </Grid>

                        <Grid item xs={12} md={5}> 

                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>

                                <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />

                                <TextField 
                                    id="input-buscar" 
                                    label="Buscar expediente" 
                                    variant="standard" 
                                    style={{ width: '100%' }} 
                                    autoComplete="off"
                                    value={ numeroExpedienteSearch }
                                    disabled={ filtroAplicado }
                                    onChange={ ( e ) => { setNumeroExpedienteSearch( e.target.value ); } }
                                />

                            </Box>

                        </Grid>

                        <Grid item xs={12} md={5}> 

                            <FormControl fullWidth>
                                <InputLabel id="select-juzgado-label">Juzgado</InputLabel>
                            
                                <Select
                                    labelId="select-juzgado-label"
                                    id="select-juzgado"                                            
                                    label="Juzgado"
                                    name="juzgado"
                                    value={ juzgado }
                                    disabled={ filtroAplicado }
                                    onChange={ (e) => { setJuzgado( e.target.value ); } }
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                   
                                    {
                                        juzgadoArray
                                        .filter( ( elem ) => { return elem.idJuzgado === 72 || elem.idJuzgado === 74 || elem.idJuzgado === 34; })
                                        .map( ( elem ) => 
                                            <MenuItem key={ elem.idJuzgado } value={ elem.idJuzgado }>{ elem.descripcion }</MenuItem> 
                                        )
                                    }                     
                                    
                                </Select>

                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={2}> 

                            <LoadingButton
                                variant='contained'
                                startIcon={ filtroAplicado ? <ClearIcon /> : <SearchIcon /> }
                                fullWidth
                                color={ filtroAplicado ? 'inherit' : 'primary' }
                                disabled={ numeroExpedienteSearch === '' && juzgado === 0 }
                                onClick={ () => { filtroAplicado ? handleClearFiltro() : handleBuscarExpedienteFiltro() } }
                                loading={ loadingSearch }
                            >
                                { filtroAplicado ? 'limpiar filtro' : 'Buscar'}
                            </LoadingButton>
                            
                        </Grid>  
                        
                    </Grid>

                    <Grid container spacing={3} sx={{ mt: 3 }}>

                        <Grid item md={12} xs={12}>

                            <TableContainer>

                                <Table sx={{ minWidth: 500 }} >

                                    <TableHead>

                                        <TableRow>        

                                            <TableCell style={{ width:'1%', fontWeight: 'bold', textAlign: 'center' }}>  </TableCell>
                                            <TableCell style={{ width:'2%', fontWeight: 'bold', textAlign: 'center' }}> FOLIO </TableCell>
                                            <TableCell style={{ width:'2%', fontWeight: 'bold', textAlign: 'center' }}> EXPEDIENTE </TableCell>
                                            <TableCell style={{ width:'10%', fontWeight: 'bold', textAlign: 'center' }}> JUZGADO </TableCell>
                                            <TableCell style={{ width:'5%', fontWeight: 'bold', textAlign: 'center' }}> EXP. ORIGEN </TableCell>
                                            <TableCell style={{ width:'10%', fontWeight: 'bold', textAlign: 'center' }}> JUZGADO ORIGEN </TableCell>       
                                            <TableCell style={{ width:'1%', fontWeight: 'bold', textAlign: 'center' }}> </TableCell>                                         
                                            <TableCell style={{ width:'1%', fontWeight: 'bold', textAlign: 'center' }}> DETALLE </TableCell>                                            

                                        </TableRow>

                                    </TableHead>

                                    <TableBody>
                                    {
                                        rows.map( ( row ) => (

                                            <TableRow key={ row.idExpediente } >

                                                <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                    { row?.id }
                                                </TableCell>

                                                <TableCell sx={{ textAlign: 'center' }}>
                                                    { row?.folio }
                                                </TableCell>

                                                <TableCell sx={{ textAlign: 'center' }}>
                                                    { row?.numeroExpediente }
                                                </TableCell>

                                                <TableCell>
                                                    { row?.juzgado }
                                                </TableCell>
                                                
                                                <TableCell sx={{  textAlign: 'center' }}>
                                                    { row?.numeroExpedienteOrigen }
                                                </TableCell>

                                                <TableCell>
                                                    { row?.juzgadoOrigen }
                                                </TableCell>

                                                <TableCell sx={{ textAlign: 'center' }}>

                                                    <Button
                                                        variant='contained'
                                                        color="primary" 
                                                        disabled={ !row?.existeDocumento }
                                                        onClick={ () => { setDocumento( row?.documento ?? '' ); setLoadingDocumento( true ); setOpenDocumento( true ); } }
                                                    >                                                        
                                                        <FileCopyIcon />
                                                    </Button>  

                                                </TableCell>  

                                                <TableCell sx={{ textAlign: 'center' }}>

                                                    <Button
                                                        variant='contained'
                                                        color="primary" 
                                                        onClick={ () => navigate(`/detalle/${ row.idExpediente }`) }
                                                    >                                                        
                                                        <ListIcon />
                                                    </Button>  

                                                </TableCell>                                                

                                            </TableRow>
                                            
                                        ))
                                    }

                                    { rows.length === 0 && (

                                        <TableRow style={{ height: 53 }}>
                                            
                                            <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                {
                                                    loading
                                                    ?
                                                        <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                    :
                                                        ''
                                                }
                                                {
                                                    !loading && rows.length === 0
                                                    ?
                                                        'No se encontraron expedientes'
                                                    :
                                                        ''
                                                }
                                            </TableCell>

                                        </TableRow>

                                    )}
                                    </TableBody>

                                    <TableFooter>

                                        <TableRow>

                                            <TableCell colSpan={2}>
                                                <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de registros: { recordsCount } </Typography>
                                            </TableCell>
                                        
                                            <TablePagination
                                                rowsPerPageOptions={[ 5, 10, 15, 25, 50, 100 ]}
                                                colSpan={12}
                                                count={ recordsCount }                                        
                                                rowsPerPage={rowsPerPage}
                                                page={page}                                                                                
                                                onPageChange={ handleChangePage }
                                                onRowsPerPageChange={ handleChangeRowsPerPage }
                                                ActionsComponent={ TablePaginationActions }

                                                labelRowsPerPage="Filas por página"                                            
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                    return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }  
                                            />
                                        </TableRow>

                                    </TableFooter>

                                </Table>

                            </TableContainer>

                        </Grid>

                    </Grid>

                    <Dialog maxWidth='md' fullWidth open={ openDocumento } onClose={ () => { } } >  
                        {
                            loadingDocumento
                            &&
                            <LinearProgress size={100}/>
                        }          
                
                        <DialogContent>

                            <Grid container sx={{ mt: 1 }}>                                   

                                <Grid item xs={12}>
                                                    
                                    <iframe 
                                        title='iframe digital'
                                        style={{ width: '100%', height: 500, border: 'none' }} 
                                        src={ documento }      
                                        onLoad={ () => setLoadingDocumento( false ) }                          
                                    />                                           

                                </Grid>       
                            </Grid>   

                        </DialogContent>
                
                        <DialogActions>
                            <Button onClick={ () => { setOpenDocumento( false ); } }>Cerrar</Button>
                        </DialogActions>

                    </Dialog>

                </>
            </DrawerCustom> 

        </>
    )
}