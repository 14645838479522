
import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        status: 'not-authenticated', 
        token: null,
        nombres: null,
        apellidos: null,      
        correoElectronico: null,      
        tipoUsuario: null,   
        message: null, 
    },
    reducers: {
        login: ( state, { payload } ) => {
            state.status = 'authenticated';
            state.token = payload.token;
            state.nombres = payload.nombres;
            state.apellidos = payload.apellidos;
            state.correoElectronico = payload.correoElectronico;
            state.tipoUsuario = payload.tipoUsuario;
            state.message = null;      
        },
        logout: ( state ) => {            
            state.status = 'not-authenticated';
            state.token = null;
            state.nombres = null;
            state.apellidos = null;
            state.correoElectronico = null;
            state.message = null;
            state.tipoUsuario = 0;
        },
        authError: ( state, { payload } ) => {  
            state.status= 'error';
            state.message = payload;
        },      
    },
})

export const { login, logout, authError } = authSlice.actions

export default authSlice.reducer