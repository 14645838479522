import React from 'react';

import { Provider } from "react-redux";

import { createTheme, ThemeProvider } from '@mui/material';

import { AppRouter } from "./routers/AppRouter";

import { store } from './store';

const theme = createTheme({
    palette: {
        primary: {            
            main: '#0A192D',
        },
        secondary: {
            main: '#0A192D',
        },
        info: {
            main: '#0A192D'
        },
    },
});

function App() {

    return (

        <Provider store = { store }>

            <ThemeProvider theme={theme}>
            
                <AppRouter />
            
            </ThemeProvider>

        </Provider>
    );

}

export default App;