import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import EditIcon from '@mui/icons-material/Edit';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import KeyIcon from '@mui/icons-material/Key';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';

import { DrawerCustom } from '../../ui/DrawerCustom';

import { usePages } from '../../../hooks/usePages';

import { TablePaginationActions } from '../../../helpers/TablePaginationActions';

import { Activar, Actualizar, Agregar, CambiarContrasena, ObtenerUsuarios, VerContrasena } from '../../../actions/admin/usuarios/usuarioActions';
import { ObtenerTipoUsuarios } from '../../../actions/admin/catalogos/tipoUsuarioActions';

import { NotFoundScreen } from '../../auth/NotFoundScreen';

export const UsuariosScreen = () => {

    const { tipoUsuario: tipoUsuarioRedux } = useSelector( state => state.auth );
    
    const [openDetalle, setOpenDetalle] = useState(false);

    const [rows, setRows] = useState( [ ] );

    const [viewContrasena, setViewContrasena] = useState( false );
    const [viewConfirmarContrasena, setViewConfirmarContrasena] = useState( false );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages( 10 );

    const [opcion, setOpcion] = useState( '' );

    const [openDP, setOpenDP] = useState(false);
    const [usuarioSelected, setUsuarioSelected] = useState( 0 );
    const [usuarioSelectedTipo, setUsuarioSelectedTipo] = useState( false );

    const [loadingActivarDesactivar, setLoadingActivarDesactivar] = useState(false);
    const [loadingEditarUsuario, setLoadingEditarUsuario] = useState(false);
    const [loadingCambiarContrasena, setLoadingCambiarContrasena] = useState(false);

    const [loading, setLoading] = useState( true );

    const [openContrasena, setOpenContrasena] = useState(false);

    const [modalMessage, setModalMessage] = useState( { openMessage: false, message: '', type: 'error' } );
    const { openMessage, message, type } = modalMessage;

    const [nombres, setNombres] = useState('');
    const [apellido1, setApellido1] = useState('');
    const [apellido2, setApellido2] = useState('');
    const [correoElectronico, setCorreoElectronico] = useState('');

    const [tipoUsuario, setTipoUsuario] = useState( 0 );
    const [tipoUsuarioArray, setTipoUsuarioArray] = useState( [ ] );

    const [contrasena, setContrasena] = useState('');
    const [contrasenaConfirmar, setContrasenaConfirmar] = useState('');

    const [errors, setErrors] = useState( { } );  

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }

        setModalMessage({
            ...modalMessage,
            openMessage: false,
        });     
        
    };

    const handleChangeViewContrasena = () => setViewContrasena( ( prev ) => !prev );
    const handleChangeViewConfirmarContrasena = () => setViewConfirmarContrasena( ( prev ) => !prev );

    const validateFiels = () => {
        let valid = true;
        let errores = {};

        setErrors( {} );  

        const validEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(correoElectronico);

        if( nombres === '' ){
            valid = false;
            errores.nombres = 'Debes escribir el nombre';
        }
        if( correoElectronico === '' ){
            valid = false;
            errores.correoElectronico = 'Debes escribir el correo electrónico';
        }
        if(!validEmail){   
            valid = false;
            errores.correoElectronico = 'El correo electrónico no tiene el formato correcto';
        }
        if( tipoUsuario === 0 ){
            valid = false;
            errores.tipoUsuario = 'Seleccione el tipo de usuario';
        }      
       
        setErrors( errores );        

        return valid;
    } 

    const validateFielsContrasena = () => {
        let valid = true;
        let errores = {};

        setErrors( {} );  

        if( contrasena === '' ){
            valid = false;
            errores.contrasena = 'Debes escribir la contraseña';
        }
        if( contrasenaConfirmar === '' ){
            valid = false;
            errores.contrasenaConfirmar = 'Debes confirmar la contraseña';
        }
        if( contrasena !== '' && contrasenaConfirmar !== '' && ( contrasena !== contrasenaConfirmar ) ){
            valid = false;
            errores.validarContrasena = 'Las contraseñas no coinciden';
        }
       
        setErrors( errores );        

        return valid;
    } 

    const handleDenegarPermitir = async ( ) => {

        const params = {
            idUsuario : usuarioSelected,
            activo: usuarioSelectedTipo
        }

        setLoadingActivarDesactivar( true );

        await Activar( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    if( response.data ){

                        const { success, message } = response.data;

                        if( success ){

                            setTimeout(() => {

                                setLoadingActivarDesactivar( false );
                                
                                setRows( rows.map( ( element ) => { 
                                    if( element.id === usuarioSelected ){            
                                        element.activo = usuarioSelectedTipo; 
                                        return element; 
                                    }
                                    else{
                                        return element; 
                                    }
                                }));

                                setModalMessage({
                                    openMessage: true,
                                    message: message,
                                    type: 'success'
                                });

                                setOpenDP( false );
                                setUsuarioSelected( 0 );

                            }, 500);
                        }
                        else{
                            setModalMessage({
                                openMessage: true,
                                message: message,
                                type: 'error'
                            });
                            setOpenDP( false );
                            setLoadingActivarDesactivar( false );
                        }
                    }
                    else{
                        setLoadingActivarDesactivar( false );
                        setModalMessage({
                            openMessage: true,
                            message: 'ocurrio un problema',
                            type: 'error'
                        });
                        setOpenDP( false );
                    }
                }
                else{
                    setLoadingActivarDesactivar( false );
                    setModalMessage({
                        openMessage: true,
                        message: 'ocurrio un problema',
                        type: 'error'
                    });
                    setOpenDP( false );
                }
            }
            else{
                setLoadingActivarDesactivar( false );
                setModalMessage({
                    openMessage: true,
                    message: 'ocurrio un problema',
                    type: 'error'
                });
                setOpenDP( false );
            }
        });

    }

    const handleEdit = ( data ) => {

        setUsuarioSelected( data?.id ?? 0 );
        setNombres( data?.nombres ?? '' );
        setApellido1( data?.apellido1 ?? '' );
        setApellido2( data?.apellido2 ?? '' );
        setCorreoElectronico( data?.correoElectronico ?? '' );
        setTipoUsuario( data?.idTipoUsuario ?? 0 );

        setOpcion('edit');
        setOpenDetalle( true );
    }

    const limpiarValores = ( data ) => {

        setUsuarioSelected( 0 );
        setNombres( '' );
        setApellido1( '' );
        setApellido2( '' );
        setCorreoElectronico( '' );
       
        setTipoUsuario( 0 );
        setViewContrasena( false );
        setViewConfirmarContrasena( false );

        setOpcion('');
    }

    const handleGuardarUsuario = async () => {
        
        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        const params = {
            IdUsuario: usuarioSelected,
            CorreoElectronico: correoElectronico,
            Nombres: nombres,
            Apellido1: apellido1,
            Apellido2: apellido2,
            IdTipoUsuario: tipoUsuario,
        }

        setLoadingEditarUsuario( true );

        if( opcion === 'add' ){

            await Agregar( params ).then( response => {

                if( response ){
    
                    if( response.status === 200 ){
    
                        if( response.data ){
    
                            const { success, message, data } = response.data;
    
                            if( success ){
    
                                setTimeout(() => {
    
                                    setLoadingEditarUsuario( false );
                                    
                                    setRows( [ ...rows, data ] );
    
                                    setModalMessage({
                                        openMessage: true,
                                        message: message,
                                        type: 'success'
                                    });
    
                                    setOpenDetalle( false );
                                    setUsuarioSelected( 0 );
                                    limpiarValores();
    
                                }, 500);
                            }
                            else{
                                setModalMessage({
                                    openMessage: true,
                                    message: message,
                                    type: 'error'
                                });
                                setOpenDetalle( false );
                                setLoadingEditarUsuario( false );
                            }
                        }
                        else{
                            setLoadingEditarUsuario( false );
                            setModalMessage({
                                openMessage: true,
                                message: 'ocurrio un problema',
                                type: 'error'
                            });
                            setOpenDetalle( false );
                        }
                    }
                    else{
                        setLoadingEditarUsuario( false );
                        setModalMessage({
                            openMessage: true,
                            message: 'ocurrio un problema',
                            type: 'error'
                        });
                        setOpenDetalle( false );
                    }
                }
                else{
                    setLoadingEditarUsuario( false );
                    setModalMessage({
                        openMessage: true,
                        message: 'ocurrio un problema',
                        type: 'error'
                    });
                    setOpenDetalle( false );
                }
            });

        }
        else if( opcion === 'edit' ){

            await Actualizar( params ).then( response => {

                if( response ){
    
                    if( response.status === 200 ){
    
                        if( response.data ){
    
                            const { success, message, data } = response.data;
    
                            if( success ){
    
                                setTimeout(() => {
    
                                    setLoadingEditarUsuario( false );
                                    
                                    setRows( rows.map( ( element ) => { 
                                        if( element.id === usuarioSelected ){    
                                            
                                            element.nombres = data.nombres;
                                            element.apellido1 = data.apellido1;
                                            element.apellido2 = data.apellido2;
                                            element.nombreCompleto = data.nombreCompleto;
                                            element.correoElectronico = data.correoElectronico;                                          
                                            element.idTipoUsuario = data?.idTipoUsuario ?? 0;
                                            element.tipoUsuario = data?.tipoUsuario;                                            
                                            element.activo = data.activo; 

                                            return element; 
                                        }
                                        else{
                                            return element; 
                                        }
                                    }));
    
                                    setModalMessage({
                                        openMessage: true,
                                        message: message,
                                        type: 'success'
                                    });
    
                                    setOpenDetalle( false );
                                    setUsuarioSelected( 0 );
    
                                }, 500);
                            }
                            else{
                                setModalMessage({
                                    openMessage: true,
                                    message: message,
                                    type: 'error'
                                });
                                setOpenDetalle( false );
                                setLoadingEditarUsuario( false );
                            }
                        }
                        else{
                            setLoadingEditarUsuario( false );
                            setModalMessage({
                                openMessage: true,
                                message: 'ocurrio un problema',
                                type: 'error'
                            });
                            setOpenDetalle( false );
                        }
                    }
                    else{
                        setLoadingEditarUsuario( false );
                        setModalMessage({
                            openMessage: true,
                            message: 'ocurrio un problema',
                            type: 'error'
                        });
                        setOpenDetalle( false );
                    }
                }
                else{
                    setLoadingEditarUsuario( false );
                    setModalMessage({
                        openMessage: true,
                        message: 'ocurrio un problema',
                        type: 'error'
                    });
                    setOpenDetalle( false );
                }
            });

        }

    }

    const handleContrasena = async ( id ) => {

        await VerContrasena( { idUsuario: id, contrasena: '' } ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    if( response.data ){

                        const { data } = response.data;

                        setContrasena( data );
                        setContrasenaConfirmar( data );

                        setUsuarioSelected( id );
                        setOpenContrasena( true );

                    }
                }
            }

        });
        
    }

    const handleCambiarContrasena = async () => {

        const valid = validateFielsContrasena();

        if( !valid ){
            return false;
        }

        const params = {
            IdUsuario: usuarioSelected,
            contrasena: contrasena,
        }

        setLoadingCambiarContrasena( true );

        await CambiarContrasena( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    if( response.data ){

                        const { success, message } = response.data;

                        if( success ){

                            setTimeout(() => {

                                setLoadingCambiarContrasena( false );

                                setModalMessage({
                                    openMessage: true,
                                    message: message,
                                    type: 'success'
                                });

                                setOpenContrasena( false );
                                setUsuarioSelected( 0 );
                                limpiarValores();

                            }, 500);
                        }
                        else{
                            setModalMessage({
                                openMessage: true,
                                message: message,
                                type: 'error'
                            });
                            setOpenContrasena( false );
                            setLoadingCambiarContrasena( false );
                        }
                    }
                    else{
                        setLoadingCambiarContrasena( false );
                        setModalMessage({
                            openMessage: true,
                            message: 'ocurrio un problema',
                            type: 'error'
                        });
                        setOpenContrasena( false );
                    }
                }
                else{
                    setLoadingCambiarContrasena( false );
                    setModalMessage({
                        openMessage: true,
                        message: 'ocurrio un problema',
                        type: 'error'
                    });
                    setOpenContrasena( false );
                }
            }
            else{
                setLoadingCambiarContrasena( false );
                setModalMessage({
                    openMessage: true,
                    message: 'ocurrio un problema',
                    type: 'error'
                });
                setOpenContrasena( false );
            }
        });

    }

    const handleAgregarUsuario = () => {

        setNombres( '' );
        setApellido1( '' );
        setApellido2( '' );
        setCorreoElectronico( '' );
       
        setTipoUsuario( 0 );
        setViewContrasena( false );
        setViewConfirmarContrasena( false );

        setOpenDetalle( true ); 
        setOpcion( 'add' ); 
        setUsuarioSelected( 0 );

    }

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerTipoUsuarios().then( response => {

                if( response ){

                    if( response.data ){

                        setTipoUsuarioArray( response.data );

                    }

                }

            });

        }

        Obtener();
        
    }, [ ])

    useEffect(() => {
      
        async function Obtener(){

            setLoading( true );
            
            await ObtenerUsuarios().then(response => {
                
                if( response ){

                    if( response.status === 200 ){

                        setTimeout(() => {
                        
                            setRows( response.data );
                            setLoading( false );

                        }, 700);
                        
                    }
                    else{
                        setLoading( false );
                    }
                }
                else{
                    setLoading( false );
                }
                
            });

        }

        Obtener();
        
    }, [ ])
    
    return (
        <>
            {
                tipoUsuarioRedux !== 2
                ?
                    <NotFoundScreen />
                :
                <>

                <Snackbar open={ openMessage } anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={ 2000 } onClose={ handleClose }>
                    <Alert onClose={ handleClose } variant="filled" severity={ type } sx={{ width: '100%' }}>
                        { message }
                    </Alert>
                </Snackbar>

                <DrawerCustom>

                    <>
                        <Grid container spacing={3} sx={{ mt: 1 }}>

                            <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}>

                                <Button
                                    variant='contained'
                                    onClick={ handleAgregarUsuario }
                                    startIcon={ <AddIcon /> }
                                >
                                    Crear usuario
                                </Button>

                            </Grid>

                            <Grid item md={12} xs={12} sx={{ mt: 3 }}>

                                <TableContainer>

                                    <Table>

                                        <TableHead>

                                            <TableRow>  
                                                
                                                <TableCell style={{ width:'15%', fontWeight: 'bold' }}> NOMBRE COMPLETO </TableCell>
                                                <TableCell style={{ width:'15%', fontWeight: 'bold' }}> CORREO ELECTRÓNICO </TableCell>      
                                                <TableCell style={{ width:'10%', fontWeight: 'bold' }}> TIPO DE USUARIO </TableCell>                                                                   
                                                <TableCell style={{ width:'10%', fontWeight: 'bold' }}> ACCESSO </TableCell> 
                                                <TableCell style={{ width:'1%', fontWeight: 'bold' }}> CONTRASEÑA </TableCell>   
                                                <TableCell style={{ width:'1%', fontWeight: 'bold' }}> EDITAR </TableCell>   

                                            </TableRow>

                                        </TableHead>

                                        <TableBody>
                                            {
                                                (
                                                    rowsPerPage > 0
                                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : rows

                                                ).map((row) => (
                                                    
                                                    <TableRow key={ row.id } >

                                                        <TableCell>
                                                            { row.nombreCompleto } 
                                                        </TableCell>                                                  
                                                        
                                                        <TableCell>
                                                            { row.correoElectronico }
                                                        </TableCell>  

                                                        <TableCell>
                                                            { row.tipoUsuario }
                                                        </TableCell>       
                                
                                                        <TableCell>

                                                            {
                                                                row.activo
                                                                ?
                                                                    <Button
                                                                        variant='contained'
                                                                        color='error'
                                                                        startIcon={ <DoDisturbIcon /> }
                                                                        onClick={ () => { 
                                                                                setUsuarioSelected( row.id );  
                                                                                setUsuarioSelectedTipo( false );
                                                                                setOpenDP( true );
                                                                            } 
                                                                        }
                                                                        size='small'
                                                                    >
                                                                        Desactivar
                                                                    </Button>
                                                                :
                                                                    <Button
                                                                        variant='contained'
                                                                        color='success'
                                                                        startIcon={ <CheckIcon /> }
                                                                        onClick={ () => { 
                                                                                setUsuarioSelected( row.id );  
                                                                                setUsuarioSelectedTipo( true );
                                                                                setOpenDP( true );
                                                                            } 
                                                                        }
                                                                        size='small'
                                                                    >
                                                                        Activar
                                                                    </Button>
                                                            }

                                                        </TableCell>     

                                                        <TableCell sx={{ textAlign: 'center' }}>
                                                            
                                                            <IconButton 
                                                                color='primary'
                                                                onClick={ () => { handleContrasena( row.id ); } }
                                                            >
                                                                <KeyIcon />
                                                            </IconButton>

                                                        </TableCell>   

                                                        <TableCell sx={{ textAlign: 'center' }}>
                                                            
                                                            <IconButton 
                                                                color='primary'
                                                                onClick={ () => { handleEdit( row ); } }
                                                            >
                                                                <EditIcon />
                                                            </IconButton>

                                                        </TableCell>   

                                                    </TableRow>
                                                    
                                                ))
                                            } 

                                            { 
                                                rows.length === 0 && (
                                                    <TableRow style={{ height: 53 }}>
                                                        
                                                        <TableCell colSpan={10} align="center"> 
                                                            {
                                                                loading
                                                                ?
                                                                    <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                                :
                                                                    ''
                                                            }
                                                            {
                                                                !loading && rows.length === 0
                                                                ?
                                                                    'No se encontraron solicitudes'
                                                                :
                                                                    ''
                                                            }
                                                        </TableCell>

                                                    </TableRow>
                                                )   
                                            }

                                        </TableBody>

                                        <TableFooter>

                                            <TableRow>

                                                <TablePagination
                                                    colSpan={8}
                                                    rowsPerPageOptions={[ 5, 10, 15, 25, 50, 100 ]}
                                                    count={ rows.length }
                                                    rowsPerPage={ rowsPerPage }
                                                    page={ page }
                                                    onPageChange={ handleChangePage }
                                                    onRowsPerPageChange={ handleChangeRowsPerPage }

                                                    ActionsComponent={ TablePaginationActions }

                                                    labelRowsPerPage="Filas por página"                                            
                                                    labelDisplayedRows={
                                                        ({ from, to, count }) => {
                                                            return '' + from + '-' + to + ' de ' + count
                                                        }
                                                    }        
                                                />
                                            </TableRow>

                                        </TableFooter>

                                    </Table>

                                </TableContainer>

                            </Grid>

                        </Grid>              

                        <Dialog open={ openDetalle } onClose={ () => {} } >
                                            
                            <DialogContent>
                            
                                <Grid container spacing={3} sx={{ mt: 1 }}>
                                            
                                    <Grid item md={12} xs={12} >
                                        
                                        <TextField
                                            fullWidth
                                            label="Nombre(s)"
                                            name="nombres"
                                            variant="outlined"
                                            autoComplete='off'
                                            required
                                            value={ nombres }                                
                                            onChange={ ( e ) => { setNombres( (e.target.value.toUpperCase()) ); } }
                                        />
                                        {                           
                                            errors.nombres && ( <Alert color='warning' severity="warning" variant="filled" sx={{ mt: 1 }}> { errors.nombres } </Alert> )
                                        }
                                    </Grid>   

                                    <Grid item md={6} xs={12} >
                                    
                                        <TextField
                                            fullWidth
                                            label="Apellido 1"
                                            name="apellido1"
                                            variant="outlined"
                                            autoComplete='off'
                                            value={ apellido1 }                                
                                            onChange={ ( e ) => { setApellido1( (e.target.value).toUpperCase() ); } }
                                        />
                                        {                           
                                            errors.apellido1 && ( <Alert color='warning' severity="warning" variant="filled" sx={{ mt: 1 }}> { errors.apellido1 } </Alert> )
                                        }
                                    </Grid>   

                                    <Grid item md={6} xs={12} >
                                    
                                        <TextField
                                            fullWidth
                                            label="Apellido 2"
                                            name="apellido2"
                                            variant="outlined"
                                            autoComplete='off'
                                            value={ apellido2 }                                
                                            onChange={ ( e ) => { setApellido2( (e.target.value).toUpperCase() ); } }
                                        />

                                    </Grid>   
                                            
                                    <Grid item md={12} xs={12} >

                                        <TextField
                                            fullWidth
                                            label="Correo electrónico"
                                            name="correoElectronico"
                                            variant="outlined"
                                            required
                                            value={ correoElectronico }                                
                                            onChange={ ( e ) => { setCorreoElectronico( e.target.value ); } }
                                        />
                                        {                           
                                            errors.correoElectronico && ( <Alert color='warning' severity="warning" variant="filled" sx={{ mt: 1 }}> { errors.correoElectronico } </Alert> )
                                        }
                                    </Grid>  

                                    <Grid item md={12} xs={12}>
                                            
                                        <FormControl fullWidth>
                                            <InputLabel id="select-tipoUsuario-label">Tipo de usuario</InputLabel>
                                        
                                            <Select
                                                labelId="select-tipoUsuario-label"
                                                id="select-tipoUsuario"                                            
                                                label="Tipo de usuario"
                                                name="tipoUsuario"
                                                value={ tipoUsuario }                                
                                                onChange={ ( e ) => { setTipoUsuario( e.target.value ); } }
                                            >
                                                <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                {                                      
                                                    tipoUsuarioArray
                                                    .map( ( element ) => (
                                                        <MenuItem key={ element.idTipoUsuario } value={ element.idTipoUsuario }>{ element.descripcion }</MenuItem>
                                                    ))                                       
                                                }
                                            </Select>

                                        </FormControl>
                                        {                           
                                            errors.tipoUsuario && ( <Alert color='warning' severity="warning" variant="filled" sx={{ mt: 1 }}> { errors.tipoUsuario } </Alert> )
                                        }
                                    </Grid>

                                </Grid>  
                            
                            </DialogContent>

                            <DialogActions>
                                <Button onClick={ () => { setOpenDetalle( false ); setErrors( {} ); limpiarValores(); } } > Cancelar </Button>
                                <LoadingButton 
                                    onClick={ handleGuardarUsuario } 
                                    variant='contained' 
                                    loading={ loadingEditarUsuario } 
                                    startIcon={ <SaveIcon /> }
                                > 
                                    
                                    { opcion === 'edit' ? 'Actualizar' : '' }
                                    { opcion === 'add' ? 'Agregar' : '' }

                                </LoadingButton>  
                            </DialogActions>
                        </Dialog>

                        <Dialog open={ openContrasena } onClose={ () => {} } >
                                            
                            <DialogContent>
                            
                                <Grid container spacing={3} sx={{ mt: 1 }}>

                                    <Grid item md={12} xs={12} >

                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel>Contraseña</InputLabel>
                                            <OutlinedInput
                                                label="Contraseña"
                                                name="contrasena"
                                                type={ viewContrasena ? 'text' : 'password'}                                            
                                                value={ contrasena }                                
                                                onChange={ ( e ) => { setContrasena( e.target.value ); } }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={ handleChangeViewContrasena } edge="end" >
                                                            {
                                                                viewContrasena
                                                                ?
                                                                <VisibilityOffIcon />
                                                                :
                                                                <VisibilityIcon />
                                                            }                                                    
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        {                           
                                            errors.contrasena && ( <Alert color='warning' severity="warning" variant="filled" sx={{ mt: 1 }}> { errors.contrasena } </Alert> )
                                        }
                                    </Grid>    
                                            
                                    <Grid item md={12} xs={12} >

                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel>Confirmar contraseña</InputLabel>
                                            <OutlinedInput
                                                label="Confirmar contraseña"
                                                name="confirmarContrasena"
                                                type={ viewConfirmarContrasena ? 'text' : 'password'}                                            
                                                value={ contrasenaConfirmar }                                
                                                onChange={ ( e ) => { setContrasenaConfirmar( e.target.value ); } }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={ handleChangeViewConfirmarContrasena } edge="end" >
                                                            {
                                                                viewConfirmarContrasena
                                                                ?
                                                                <VisibilityOffIcon />
                                                                :
                                                                <VisibilityIcon />
                                                            }                                                    
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        {                           
                                            errors.contrasenaConfirmar && ( <Alert color='warning' severity="warning" variant="filled" sx={{ mt: 1 }}> { errors.contrasenaConfirmar } </Alert> )
                                        }
                                    </Grid>    

                                    <Grid item md={12} xs={12}>
                                        {                           
                                            errors.validarContrasena && ( <Alert color='warning' severity="warning" variant="filled"> { errors.validarContrasena } </Alert> )
                                        }
                                    </Grid>

                                </Grid>  
                                
                            </DialogContent>

                            <DialogActions>
                                <Button onClick={ () => { setOpenContrasena( false ); setErrors( {} ); limpiarValores(); } } > Cancelar </Button>
                                <LoadingButton 
                                    onClick={ handleCambiarContrasena } 
                                    variant='contained' 
                                    startIcon={ <SaveIcon /> } 
                                    loading={ loadingCambiarContrasena }
                                > 
                                    Actualizar 
                                </LoadingButton>  
                            </DialogActions>
                        </Dialog>

                        <Dialog open={ openDP } onClose={ () => {} } >
                            
                            <DialogTitle>
                                Confirmación
                            </DialogTitle>
                            
                            <DialogContent>
                            
                                <DialogContentText style={{ color: 'rgba(0, 0, 0, 0.8)' }}>
                                    ¿Desea { usuarioSelectedTipo ? 'activar' : 'desactivar' } al usuario seleccionado?
                                </DialogContentText>
                            
                            </DialogContent>

                            <DialogActions>
                                <Button onClick={ () => { setOpenDP( false ); } }> Cancelar </Button>
                                <LoadingButton 
                                    onClick={ handleDenegarPermitir } 
                                    variant='contained' 
                                    loading={ loadingActivarDesactivar }
                                    startIcon={ <SaveIcon /> }
                                > 
                                    Aceptar 
                                </LoadingButton>
                            </DialogActions>
                        </Dialog>
                    </>

                </DrawerCustom>

                </>
            }
        </>
    );
}