import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Container, Grid, Box, Typography, Stack, TextField, Paper, Alert, Snackbar, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import LoginIcon from '@mui/icons-material/Login';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { Login } from '../../actions/auth/authActions';
import { useForm } from '../../hooks/useForm';

export const LoginScreen = () => {

    const dispatch = useDispatch();

    const { message } = useSelector( state => state.auth );

    const { correoElectronico, contrasena, onChange } = useForm({ correoElectronico: '', contrasena: '' });

    const [{ open, msg, type }, setAlert] = useState({
        open: false,
        msg: '',
        type: 'warning'
    });

    const [viewContrasena, setViewContrasena] = useState( false );

    const [loading, setLoading] = useState( false );

    const handleChangeViewContrasena = () => {
        setViewContrasena( ( prev ) => !prev );
    }

    const validateFiels = () => {

        let valid = true;

        const validEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(correoElectronico);

        if(!correoElectronico){    
            valid = false;
            setAlert({
                open: true,
                msg: 'Escribe el correo electrónico',
                type: 'warning'
            });
        }        
        else if(!validEmail){   
            valid = false;
            setAlert({
                open: true,
                msg: 'El correo electrónico no es valido',
                type: 'warning'
            });
        }
        else if(!contrasena){
            valid = false;
            setAlert({
                open: true,
                msg: 'Escribe la contraseña',
                type: 'warning'
            });
        }

        return valid;
    }

    const handleLogin = () => {

        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        setLoading( true );
        
        dispatch( Login({ correoElectronico, contrasena }, setLoading) )

    }

    useEffect(() => {
      
        if( message ){
            setAlert({
                open: true,
                msg: message,
                type: 'error'
            });
        }
        
    }, [ message ])

    return (
        <>
            <Snackbar open={ open } autoHideDuration={6000} onClose={ () => {} } anchorOrigin={{ vertical : 'bottom', horizontal: 'center' }} >
                <Alert onClose={ () => { setAlert({ open: false, msg, type }) } } variant='filled' severity={ type } sx={{ width: '100%' }}>
                    { msg }
                </Alert>
            </Snackbar>

            <Container maxWidth='lg' sx={{ height: '90vh' }} >
                
                <Grid container justifyContent='center' alignItems='center' sx={{ width: '100%', height: '100%' }} >
                
                    <Grid item sx={{ maxWidth: '50rem', width: '100%' }} >
                    
                        <Paper sx={{ backgroundColor: 'rgba(255, 255, 255, 1)' }} >

                            <Grid container >
                                
                                <Grid item container justifyContent='space-between' >

                                    <Grid item xs={12} sm={12} md={12} >
                                        
                                        <Typography variant='h4' component='h1' sx={{ textAlign: 'center', mt: '2rem', mb: '1.5rem', textTransform: 'uppercase' }} >
                                            Registro Hipotecario
                                        </Typography>

                                    </Grid>
                                    
                                    <Grid item xs={12} sm={12} md={5} >
                                    
                                        <Box display='flex' flexDirection='column' alignItems='center' sx={{ pr: '1em', pl: '1em', mt:{ md: '5em' } }} >
                                        
                                            <Box sx={{ display: { md: 'flex', xs: 'none' } }}>
                                                <img style={{ width: 200 }}
                                                    src={ process.env.PUBLIC_URL + '/favicon.png'}
                                                    alt='logo'
                                                />
                                            </Box>

                                            <Box sx={{ display: { md: 'none', xs: 'flex' } }}>
                                                <img style={{ width: 200 }}
                                                    src={ process.env.PUBLIC_URL + '/favicon.png'}
                                                    alt='logo'
                                                />
                                            </Box>

                                        </Box>

                                    </Grid>

                                    <Grid item xs={12} sm={12} md={7} >

                                        <Box display='flex' flexDirection='column' sx={{ pr: { md: '3em', xs: '1em' }, pl: { md: '3em', xs: '1em' }, mt:{ md: '3em' } }} >

                                            <Typography variant='h6' component='h1' sx={{ textAlign: 'center', mb: '1.5rem', textTransform: 'uppercase' }} >
                                                Ingresa con tu cuenta
                                            </Typography>

                                            <Grid container spacing={3} >

                                                <Grid item xs={12} sm={12} > 

                                                    <TextField
                                                        label='Usuario (Correo electrónico)'
                                                        type='email'
                                                        name='correoElectronico'
                                                        fullWidth
                                                        autoComplete='off'
                                                        value={ correoElectronico }
                                                        onChange={ ( e ) => onChange( e.target.value, 'correoElectronico' ) }
                                                    />

                                                </Grid>

                                                <Grid item xs={12} sm={12} > 

                                                    <FormControl variant="outlined" fullWidth >
                                                        <InputLabel>Contraseña</InputLabel>
                                                        <OutlinedInput
                                                            label="Contraseña"
                                                            name="contrasena"
                                                            type={ viewContrasena ? 'text' : 'password'}
                                                            value={ contrasena }                                
                                                            onChange={ ( e ) => onChange( e.target.value, 'contrasena' ) }
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={ handleChangeViewContrasena } edge="end" >
                                                                        {
                                                                            viewContrasena
                                                                            ?
                                                                            <VisibilityOffIcon />
                                                                            :
                                                                            <VisibilityIcon />
                                                                        }                                                    
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>  

                                                </Grid>
                                    
                                                <Grid item xs={12} sm={12} > 

                                                    <LoadingButton 
                                                        variant='contained' 
                                                        loading={ loading } 
                                                        fullWidth
                                                        startIcon={ <LoginIcon /> }
                                                        onClick={ handleLogin }
                                                    >
                                                        Ingresar
                                                    </LoadingButton>
                                                
                                                </Grid>  

                                            </Grid>

                                        </Box>

                                    </Grid>

                                </Grid>
                                
                                <Grid container justifyContent='center'>

                                    <Stack sx={{ mt: '4rem', textAlign: 'center' }}>

                                    </Stack>

                                </Grid>

                            </Grid>

                        </Paper>

                    </Grid>

                </Grid>

            </Container>
        </>
    );
}
