import React, { useEffect, useState } from 'react';

import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

import { DrawerCustom } from '../../ui/DrawerCustom';

import { usePages } from '../../../hooks/usePages';

import { TablePaginationActions } from '../../../helpers/TablePaginationActions';

import { Actualizar, Agregar, Juzgado } from '../../../actions/admin/catalogos/juzgadoActions';
import { ObtenerDistritosJudiciales } from '../../../actions/admin/catalogos/distritoJudicialActions';
import { ObtenerMaterias } from '../../../actions/admin/catalogos/materiaActions';

export const JuzgadoScreen = () => {

    const [rows, setRows] = useState( [ ] );

    const [errors, setErrors] = useState({ });

    const [descripcion, setDescripcion] = useState( '' );
    const [idReferencia, setIdReferencia] = useState( '' );
    const [opcion, setOpcion] = useState( '' );

    const [openModal, setOpenModal] = useState( false );

    const [loading, setLoading] = useState( true );
    const [loadingMovimiento, setLoadingMovimiento] = useState( false );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages( 10 );

    const [distritoJudicial, setDistritoJudicial] = useState( 0 );
    const [distritoJudicialArray, setDistritoJudicialArray] = useState( [] );

    const [materia, setMateria] = useState( 0 );
    const [materiaArray, setMateriaArray] = useState( [] );

    const handleChangeSwitch = ( id, value ) => {

        const newRows = rows.map( (row) => { if(row.idJuzgado === id){ row.activo = value; return row; } else{ return row; } } );
        setRows( newRows );        

        const juzgado = rows.filter( function(row){ return row.idJuzgado === id; } );
        cambiarJuzgado( juzgado[0] );
    }

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        setErrors( { } );
    }

    const cambiarJuzgado = async ( { idJuzgado, descripcion, activo, idDistritoJudicial, idMateria } ) => {

        const params = {
            idJuzgado,
            descripcion,
            activo,
            idDistritoJudicial,
            idMateria,
        };

        await Actualizar( params );
    }

    const AgregarRegistro = () => {

        setDescripcion( '' );
        setDistritoJudicial( 0 );
        setMateria( 0 );

        setOpcion( 'agregar' );
        setIdReferencia( 0 );

        handleOpenModal();
    }

    const EditarRegistro = ( id, descripcion, idDistritoJudicial, idMateria ) => {
        
        setDescripcion( descripcion );
        setDistritoJudicial( idDistritoJudicial );
        setMateria( idMateria );

        setOpcion( 'editar' );
        setIdReferencia( id );

        handleOpenModal();
    }

    const Guardar = async () => {

        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        setLoadingMovimiento( true );

        if( opcion === 'agregar' ){
            const params = {
                idJuzgado: 0,
                descripcion: descripcion.trim(),
                idMateria: materia,
                idDistritoJudicial: distritoJudicial,
                activo: true,
            };

            await Agregar( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        if( response.data ){
                            
                            setTimeout(() => {
                            
                                setRows([ 
                                    ...rows,  
                                    {
                                        idJuzgado: response.data.idJuzgado,
                                        descripcion: descripcion,
                                        activo: true,
                                        distritoJudicial: response.data.distritoJudicial,
                                        materia: response.data.materia,
                                    }
                                ]);  
    
                                setLoadingMovimiento( false );
                                handleCloseModal();
                            
                            }, 500);

                        } 
                    }
                }
            });
        }

        if( opcion === 'editar' ){
            const juzgado = rows.filter( function(row){ return row.idJuzgado === idReferencia; } )[0];

            const params = {
                idJuzgado: idReferencia,
                descripcion: descripcion.trim(),
                activo: juzgado.activo,
                idMateria: materia,
                idDistritoJudicial: distritoJudicial,
            };
    
            await Actualizar( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        
                        const newRows = rows.map( (row) => { 
                            if(row.idJuzgado === idReferencia){ 
                                row.descripcion = descripcion; 
                                row.idDistritoJudicial =  response.data.idDistritoJudicial; 
                                row.distritoJudicial = response.data.distritoJudicial;
                                row.idMateria =  response.data.idMateria; 
                                row.materia = response.data.materia;
                                return row; 
                            } 
                            else{ 
                                return row; 
                            } 
                        });

                        setTimeout(() => {
                        
                            setRows( newRows ); 
                        
                            setLoadingMovimiento( false );
                            handleCloseModal();

                        }, 500);
                    }
                }                
            });
        }
    }

    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores = {};

        if( descripcion.trim() === '' ){
            valid = false;
            errores.descripcion = 'Debes escribir la descripción';
        }        
        
        setErrors( errores );
        return valid;
    } 

    useEffect(() => {
        
        async function Obtener(){
            
            setLoading( true );

            await Juzgado().then(response => {
                
                if( response ){

                    if( response.status === 200 ){

                        setTimeout(() => {
                        
                            setRows( response.data );
                            setLoading( false );

                        }, 700);
                        
                    }
                    else{
                        setLoading( false );
                    }
                }
                else{
                    setLoading( false );
                }
                
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerDistritosJudiciales().then( response => {

                if( response ){

                    if( response.data ){

                        setDistritoJudicialArray( response.data );

                    }

                }

            });

        }

        Obtener();
        
    }, [ ])

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerMaterias().then( response => {

                if( response ){

                    if( response.data ){

                        setMateriaArray( response.data );

                    }

                }

            });

        }

        Obtener();
        
    }, [ ])

    return (
        <>
            <DrawerCustom>
                <>
                    <Grid container spacing={3} sx={{ mt: 1 }}>

                        <Grid item sm={6} xs={12} sx={{ fontWeight: 'bold' }}> 
                            <Typography variant='h4'>
                                Juzgado
                            </Typography>
                        </Grid>

                        <Grid item sm={6} xs={12} sx={{ textAlign: 'right' }}> 
                            <Button type="button" startIcon={ <AddCircleOutlineRoundedIcon /> } variant="contained" sx={{ mt: 1 }} onClick={ AgregarRegistro }> 
                                Agregar
                            </Button> 
                        </Grid>

                        <Grid item md={12} xs={12}>

                            <TableContainer>

                                <Table sx={{ minWidth: 500 }} >
                                    <TableHead>
                                        <TableRow>        
                                            <TableCell style={{ width:'5%', fontWeight: 'bold' }}> ID </TableCell>
                                            <TableCell style={{ width:'35%', fontWeight: 'bold' }}> DESCRIPCIÓN </TableCell>
                                            <TableCell style={{ width:'15%', fontWeight: 'bold', textAlign: 'center' }}> DISTRITO JUDICIAL </TableCell>
                                            <TableCell style={{ width:'15%', fontWeight: 'bold', textAlign: 'center' }}> MATERÍA </TableCell>
                                            <TableCell style={{ width:'10%', fontWeight: 'bold' }}> HABILITADO </TableCell>
                                            <TableCell style={{ width:'10%', fontWeight: 'bold' }}> EDITAR </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                    {
                                    (rowsPerPage > 0
                                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : rows
                                    ).map((row) => (
                                        <TableRow key={ row.idJuzgado } >
                                        
                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                { row.idJuzgado }
                                            </TableCell>

                                            <TableCell>
                                                { row.descripcion }
                                            </TableCell>

                                            <TableCell style={{ textAlign: 'center' }}>
                                                { row?.distritoJudicial?.descripcion }
                                            </TableCell>

                                            <TableCell style={{ textAlign: 'center' }}>
                                                { row?.materia?.descripcion }
                                            </TableCell>
                                            
                                            <TableCell>
                                                {
                                                    row.activo 
                                                    ? 
                                                    (
                                                        <Switch 
                                                            inputProps={ { 'aria-label': 'Switch demo'} } 
                                                            checked={ row.activo } 
                                                            onChange={ ( e ) => handleChangeSwitch( row.idJuzgado, e.target.checked ) }
                                                        />
                                                    ) 
                                                    :                                           
                                                    ( 
                                                        <Switch 
                                                            inputProps={ { 'aria-label': 'Switch demo'} } 
                                                            checked={ row.activo } 
                                                            onChange={ ( e ) => handleChangeSwitch( row.idJuzgado, e.target.checked ) }
                                                        />
                                                    )
                                                }
                                            </TableCell>

                                            <TableCell>
                                                <IconButton
                                                        color="primary" 
                                                        aria-label="edit" 
                                                        component="span" 
                                                        onClick={ () => EditarRegistro( row.idJuzgado, row.descripcion, row.idDistritoJudicial, row.idMateria ) }
                                                    >                                                        
                                                    <EditIcon />
                                                </IconButton>  
                                            </TableCell>

                                        </TableRow>
                                    ))}

                                    { rows.length === 0 && (
                                        <TableRow style={{ height: 53 }}>
                                            
                                            <TableCell colSpan={10} align="center"> 
                                                {
                                                    loading
                                                    ?
                                                        <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                    :
                                                        ''
                                                }
                                                {
                                                    !loading && rows.length === 0
                                                    ?
                                                        'No se encontraron solicitudes'
                                                    :
                                                        ''
                                                }
                                            </TableCell>

                                        </TableRow>
                                    )}
                                    </TableBody>

                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[ ]}
                                                colSpan={6}
                                                count={rows.length}                                        
                                                rowsPerPage={rowsPerPage}
                                                page={page}                                                                                
                                                onPageChange={ handleChangePage }
                                                onRowsPerPageChange={ handleChangeRowsPerPage }
                                                ActionsComponent={ TablePaginationActions }

                                                labelRowsPerPage="Filas por página"                                            
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                    return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }  
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>

                            </TableContainer>

                        </Grid>

                    </Grid>

                    <Dialog open={ openModal } maxWidth='sm' fullWidth={true}>       
                    
                        <DialogContent>

                            <Box sx={{ mt: 3 }}>

                                <FormControl fullWidth>
                                    <InputLabel id="select-distritoJudicial-label">Distrito judicial</InputLabel>
                                
                                    <Select
                                        labelId="select-distritoJudicial-label"
                                        id="select-distritoJudicial"                                            
                                        label="Distrito judicial"
                                        name="distritoJudicial"
                                        value={ distritoJudicial }
                                        onChange={ (e) => { setDistritoJudicial( e.target.value ); } }
                                    >
                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem> 

                                        {
                                            distritoJudicialArray.map( ( elem ) => 
                                                <MenuItem key={ elem.idDistritoJudicial } value={ elem.idDistritoJudicial }>{ elem.descripcion }</MenuItem> 
                                            )
                                        }                       
                                        
                                    </Select>

                                </FormControl>

                                {   
                                    errors.distritoJudicial && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.distritoJudicial } </Alert> )
                                }

                            </Box>

                            <Box sx={{ mt: 3 }}>

                                <FormControl fullWidth >
                                    <InputLabel id="select-materia-label">Matería</InputLabel>
                                
                                    <Select
                                        labelId="select-materia-label"
                                        id="select-materia"                                            
                                        label="Matería"
                                        name="materia"
                                        value={ materia }
                                        onChange={ (e) => { setMateria( e.target.value ); } }
                                    >
                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                                        
                                        {
                                            materiaArray.map( ( elem ) => 
                                                <MenuItem key={ elem.idMateria } value={ elem.idMateria }>{ elem.descripcion }</MenuItem> 
                                            )
                                        } 

                                    </Select>

                                </FormControl>

                                {   
                                    errors.materia && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.materia } </Alert> )
                                }

                            </Box>

                            <TextField
                                sx={{ mt: 3 }}
                                label='Descripción'
                                variant="outlined"      
                                autoFocus                          
                                multiline
                                rows={4}                                        
                                fullWidth
                                value={ descripcion }
                                onChange={ ( e ) => { setDescripcion( e.target.value ) } }
                            />

                            {   
                                errors.descripcion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.descripcion } </Alert> )
                            }

                        </DialogContent>

                        <DialogActions>
                            <Button onClick={ handleCloseModal }> Cancelar </Button>
                            <LoadingButton onClick={ Guardar } variant='contained' startIcon={ <SaveIcon /> } loading={ loadingMovimiento } > Guardar </LoadingButton>
                        </DialogActions>

                    </Dialog>
                </>
            </DrawerCustom>
        </>
    );
}