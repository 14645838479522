import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import { List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

import BookmarkIcon from '@mui/icons-material/Bookmark';

import { catalogos } from '../../data/catalogos';

export const ListadoCatalogos = () => {

    const { pathname } = useLocation();

    return (

        <List component="div" disablePadding>
                                    
            {
                catalogos.map( ( element ) => (
                    <Link key={ element.id } to={ element.path } style={{ textDecoration: 'none', color: 'inherit' }}>
                        <ListItemButton sx={{ pl: 4 }} selected={ pathname === element.path }>
                            <ListItemIcon>
                                <BookmarkIcon />
                            </ListItemIcon>
                            <ListItemText primary={ <Typography variant='body2'>{ element.descripcion }</Typography>  } />
                        </ListItemButton>
                    </Link>
                ))
            }

        </List>   

    )
}