
import { createSlice } from '@reduxjs/toolkit'

export const loadingSlice = createSlice({
    name: 'loading',
    initialState: {
        openLogout: false,  
    },
    reducers: {
        openLoading: ( state ) => {
            state.openLogout = true;       
        }, 
        closeLoading: ( state ) => {
            state.openLogout = false;       
        }, 
    },
})

export const { openLoading, closeLoading, } = loadingSlice.actions

export default loadingSlice.reducer