import React, { useEffect, useState } from 'react';

import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, IconButton, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

import { DrawerCustom } from '../../ui/DrawerCustom';

import { usePages } from '../../../hooks/usePages';

import { TablePaginationActions } from '../../../helpers/TablePaginationActions';

import { Actualizar, Agregar, Materia } from '../../../actions/admin/catalogos/materiaActions';

export const MateriaScreen = () => {

    const [rows, setRows] = useState( [ ] );

    const [errors, setErrors] = useState({ });

    const [descripcion, setDescripcion] = useState( '' );
    const [idReferencia, setIdReferencia] = useState( '' );
    const [opcion, setOpcion] = useState( '' );

    const [openModal, setOpenModal] = useState( false );

    const [loading, setLoading] = useState( true );
    const [loadingMovimiento, setLoadingMovimiento] = useState( false );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages( 10 );

    const handleChangeSwitch = ( id, value ) => {

        const newRows = rows.map( (row) => { if(row.idMateria === id){ row.activo = value; return row; } else{ return row; } } );
        setRows( newRows );        

        const materia = rows.filter( function(row){ return row.idMateria === id; } );
        cambiarMateria( materia[0] );
    }

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        setErrors( { } );
    }

    const cambiarMateria = async ( { idMateria, descripcion, activo } ) => {

        const params = {
            idMateria,
            descripcion,
            activo,
        };

        await Actualizar( params );
    }

    const AgregarRegistro = () => {

        setDescripcion( '' );

        setOpcion( 'agregar' );
        setIdReferencia( 0 );

        handleOpenModal();
    }

    const EditarRegistro = ( id, descripcion ) => {
        
        setDescripcion( descripcion );

        setOpcion( 'editar' );
        setIdReferencia( id );

        handleOpenModal();
    }

    const Guardar = async () => {

        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        setLoadingMovimiento( true );

        if( opcion === 'agregar' ){
            const params = {
                idMateria: 0,
                descripcion: descripcion.trim(),
                activo: true,
            };

            await Agregar( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        if( response.data ){
                            
                            setTimeout(() => {
                            
                                setRows([ 
                                    ...rows,  
                                    {
                                        idMateria: response.data.idMateria,
                                        descripcion: descripcion,
                                        activo: true
                                    }
                                ]);  
    
                                setLoadingMovimiento( false );
                                handleCloseModal();
                            
                            }, 500);

                        } 
                    }
                }
            });
        }

        if( opcion === 'editar' ){
            const materia = rows.filter( function(row){ return row.idMateria === idReferencia; } )[0];

            const params = {
                idMateria: idReferencia,
                descripcion: descripcion.trim(),
                activo: materia.activo,
            };
    
            await Actualizar( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        
                        const newRows = rows.map( (row) => { 
                            if(row.idMateria === idReferencia){ 
                                row.descripcion = descripcion; 
                                return row; 
                            } 
                            else{ 
                                return row; 
                            } 
                        });

                        setTimeout(() => {
                        
                            setRows( newRows ); 
                        
                            setLoadingMovimiento( false );
                            handleCloseModal();

                        }, 500);
                    }
                }                
            });
        }
    }

    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores = {};

        if( descripcion.trim() === '' ){
            valid = false;
            errores.descripcion = 'Debes escribir la descripción';
        }        
        
        setErrors( errores );
        return valid;
    } 

    useEffect(() => {
        
        async function Obtener(){
            
            setLoading( true );

            await Materia().then(response => {
                
                if( response ){

                    if( response.status === 200 ){

                        setTimeout(() => {
                        
                            setRows( response.data );
                            setLoading( false );

                        }, 700);
                        
                    }
                    else{
                        setLoading( false );
                    }
                }
                else{
                    setLoading( false );
                }
                
            });

        }

        Obtener();

    }, [ ]);

    return (
        <>
            <DrawerCustom>          
                <>
                    <Grid container spacing={3} sx={{ mt: 1 }}>

                        <Grid item sm={6} xs={12} sx={{ fontWeight: 'bold' }}> 
                            <Typography variant='h4'>
                                Matería
                            </Typography>
                        </Grid>

                        <Grid item sm={6} xs={12} sx={{ textAlign: 'right' }}> 
                            <Button type="button" startIcon={ <AddCircleOutlineRoundedIcon /> } variant="contained" sx={{ mt: 1 }} onClick={ AgregarRegistro }> 
                                Agregar
                            </Button> 
                        </Grid>

                        <Grid item md={12} xs={12}>

                            <TableContainer>

                                <Table sx={{ minWidth: 500 }} >
                                    <TableHead>
                                        <TableRow>        
                                            <TableCell style={{ width:'10%', fontWeight: 'bold' }}> ID </TableCell>
                                            <TableCell style={{ width:'70%', fontWeight: 'bold' }}> DESCRIPCIÓN </TableCell>
                                            <TableCell style={{ width:'10%', fontWeight: 'bold' }}> HABILITADO </TableCell>
                                            <TableCell style={{ width:'10%', fontWeight: 'bold' }}> EDITAR </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                    {
                                    (rowsPerPage > 0
                                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : rows
                                    ).map((row) => (
                                        <TableRow key={ row.idMateria} >
                                        
                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                { row.idMateria }
                                            </TableCell>

                                            <TableCell>
                                                { row.descripcion }
                                            </TableCell>
                                            
                                            <TableCell>
                                                {
                                                    row.activo 
                                                    ? 
                                                    (
                                                        <Switch 
                                                            inputProps={ { 'aria-label': 'Switch demo'} } 
                                                            checked={ row.activo } 
                                                            onChange={ ( e ) => handleChangeSwitch( row.idMateria, e.target.checked) }
                                                        />
                                                    ) 
                                                    :                                           
                                                    ( 
                                                        <Switch 
                                                            inputProps={ { 'aria-label': 'Switch demo'} } 
                                                            checked={ row.activo } 
                                                            onChange={ ( e ) => handleChangeSwitch( row.idMateria, e.target.checked) }
                                                        />
                                                    )
                                                }
                                            </TableCell>

                                            <TableCell>
                                                <IconButton
                                                        color="primary" 
                                                        aria-label="edit" 
                                                        component="span" 
                                                        onClick={ () => EditarRegistro( row.idMateria, row.descripcion ) }
                                                    >                                                        
                                                    <EditIcon />
                                                </IconButton>  
                                            </TableCell>

                                        </TableRow>
                                    ))}

                                    { rows.length === 0 && (
                                        <TableRow style={{ height: 53 }}>
                                            
                                            <TableCell colSpan={10} align="center"> 
                                                {
                                                    loading
                                                    ?
                                                        <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                    :
                                                        ''
                                                }
                                                {
                                                    !loading && rows.length === 0
                                                    ?
                                                        'No se encontraron solicitudes'
                                                    :
                                                        ''
                                                }
                                            </TableCell>

                                        </TableRow>
                                    )}
                                    </TableBody>

                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[ ]}
                                                colSpan={4}
                                                count={rows.length}                                        
                                                rowsPerPage={rowsPerPage}
                                                page={page}                                                                                
                                                onPageChange={ handleChangePage }
                                                onRowsPerPageChange={ handleChangeRowsPerPage }
                                                ActionsComponent={ TablePaginationActions }

                                                labelRowsPerPage="Filas por página"                                            
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                    return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }  
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>

                            </TableContainer>

                        </Grid>

                    </Grid>

                    <Dialog open={ openModal } maxWidth='sm' fullWidth={true}>       
                    
                        <DialogContent>

                            <TextField
                                sx={{ mt: 1 }}
                                label='Descripción'
                                variant="outlined"      
                                autoFocus                          
                                multiline
                                rows={4}                                        
                                fullWidth
                                value={ descripcion }
                                onChange={ ( e ) => { setDescripcion( e.target.value ) } }
                            />

                            {   
                                errors.descripcion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.descripcion } </Alert> )
                            }

                        </DialogContent>

                        <DialogActions>
                            <Button onClick={ handleCloseModal }> Cancelar </Button>
                            <LoadingButton onClick={ Guardar } variant='contained' startIcon={ <SaveIcon /> } loading={ loadingMovimiento } > Guardar </LoadingButton>
                        </DialogActions>

                    </Dialog>
                </>
            </DrawerCustom>        
        </>
    );
}