import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Link, useLocation } from 'react-router-dom';

import { Box, Button, CircularProgress, Collapse, Divider, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListIcon from '@mui/icons-material/List';
//import AssignmentIcon from '@mui/icons-material/Assignment';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import GroupIcon from '@mui/icons-material/Group';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import MuiAppBar from '@mui/material/AppBar';

import { styled, useTheme } from '@mui/material/styles';

import { ModalTokenExpired } from '../modal/ModalTokenExpired';

import { Logout } from '../../actions/auth/authActions';

import { ListadoCatalogos } from '../ui/ListadoCatalogos';

const drawerWidth = 250;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    }),
);

export const DrawerCustom = ({ children }) => {

    const dispatch = useDispatch();
    
    const { pathname } = useLocation();

    const { tipoUsuario } = useSelector( state => state.auth );
    const { openLogout } = useSelector( state => state.loading );

    const theme = useTheme();
    
    const [open, setOpen] = useState(true);
    const [openCatalogos, setOpenCatalogos] = useState( pathname.includes('/catalogos/') ? true : false);

    const handleDrawerOpen = () => setOpen(true)

    const handleDrawerClose = () => setOpen(false);

    const handleClick = () => setOpenCatalogos( prev => !prev);

    const handleLogout = () =>  dispatch( Logout() )

    return (
        <>
            <ModalTokenExpired />

            <Box sx={{ display: 'flex' }}>
                
                <AppBar position="fixed" open={open}>
                
                    <Toolbar>
                      
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />

                        </IconButton>                        
                    
                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                            REGISTRO HIPOTECARIO
                        </Typography>

                        <Box sx={{ flexGrow: 0 }}>

                            <Button 
                                color="inherit" 
                                onClick={ handleLogout } 
                                endIcon={ 
                                    openLogout
                                    ?
                                        <CircularProgress color='inherit' size={20} />
                                    :
                                        <ExitToAppIcon />
                                }
                            >
                                Cerrar sesión
                            </Button>
                        
                        </Box>

                    </Toolbar>

                </AppBar>

                <Drawer sx={{ width: drawerWidth, flexShrink: 0, '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box', }, }}
                    variant="persistent" anchor="left" open={open}
                >
                    
                    <DrawerHeader>
                        
                        <IconButton onClick={ handleDrawerClose }>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>

                    </DrawerHeader>
                    
                    <Divider />
                    
                    <List component="nav">

                        {/* <Link to='/inicio/' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <ListItemButton selected={ pathname === '/inicio/' } >
                                
                                <ListItemIcon>
                                    <AssignmentIcon color='primary' />
                                </ListItemIcon>
                                <ListItemText primary={ <Typography variant='body2'>Expedientes pendientes</Typography> } />

                            </ListItemButton>
                        </Link> */}

                        <Link to='/inicio/' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <ListItemButton>
                                
                                <ListItemIcon>
                                    <LowPriorityIcon color='primary' />
                                </ListItemIcon>
                                <ListItemText primary={ <Typography variant='body2'>Expedientes asignados</Typography> } />

                            </ListItemButton>
                        </Link>

                        {
                            tipoUsuario === 2
                            &&
                            <Link to='/expedientesCapturados/' style={{ textDecoration: 'none', color: 'inherit' }}>
                                <ListItemButton>
                                    
                                    <ListItemIcon>
                                        <CheckCircleOutlineIcon color='primary' />
                                    </ListItemIcon>
                                    <ListItemText primary={ <Typography variant='body2'>Expedientes capturados</Typography> } />

                                </ListItemButton>
                            </Link>
                        }   

                        {
                            tipoUsuario === 2
                            &&
                            <Link to='/usuarios/' style={{ textDecoration: 'none', color: 'inherit' }}>
                                <ListItemButton selected={ pathname === '/usuarios/' }>
                                    
                                    <ListItemIcon>
                                        <GroupIcon color='primary' />
                                    </ListItemIcon>
                                    <ListItemText primary={ <Typography variant='body2'>Usuarios</Typography> } />

                                </ListItemButton>
                            </Link>
                        }  

                        {
                            tipoUsuario === 2
                            &&
                            <>
                                <ListItemButton onClick={ handleClick }>

                                    <ListItemIcon>
                                        <ListIcon color='primary' />
                                    </ListItemIcon>
                                    <ListItemText primary={ <Typography variant='body2'>Catálogos</Typography> } />

                                    { openCatalogos ? <ExpandLessIcon /> : <ExpandMoreIcon />}

                                </ListItemButton>
                                
                                <Collapse in={ openCatalogos } timeout="auto" unmountOnExit>

                                    <ListadoCatalogos />

                                </Collapse>
                            </>
                        }      

                    </List>
                
                </Drawer>
                
                <Main open={open} sx={{ backgroundColor: '#ffffff' }}>
                    
                    <DrawerHeader />

                    { children }
                
                </Main>

            </Box>

        </>
    )
}