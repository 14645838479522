import { useState } from 'react';

export const usePages = ( initialPerPage ) => {
    
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState( initialPerPage ?? 5);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const resetPage = () =>{
      setPage(0);
    }

    return [ page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage ];
}